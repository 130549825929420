import setDefaultReturn from 'lib/junior-league-utils/set-default-return'

export const types = {
  PLACE: 'place',
  FACILITY: 'facility',
  COACH: 'coach',
  QUERY: 'query',
  EMPTY: 'empty',
}

class GeoSelection {
  static fromURLSearchParams(urlParams, { initialFilters }) {
    const label = urlParams.get('label') || ''
    const lat = urlParams.get('lat') || ''
    const lng = urlParams.get('lng') || ''

    return new this({ label, lat, lng, initialFilters })
  }

  constructor({ label, lat, lng, initialFilters }) {
    this.label = label
    this.lat = lat
    this.lng = lng
    this._initialFilters = initialFilters
  }

  get nearLabel() {
    return this.label
  }

  get initialFilters() {
    return this._initialFilters
  }

  toIndexSearchParams() {
    return {
      aroundLatLng: `${this.lat}, ${this.lng}`,
      hitsPerPage: 15,
    }
  }

  toURLSearchParams() {
    return {
      type: this.type,
      label: this.label,
      lat: this.lat,
      lng: this.lng,
    }
  }
}

export class PlaceSelection extends GeoSelection {
  get type() {
    return types.PLACE
  }
}

export class FacilitySelection extends GeoSelection {
  get type() {
    return types.FACILITY
  }
}

class BaseQuerySelection {
  static fromURLSearchParams(urlParams, { initialFilters }) {
    const query = urlParams.get('query') || ''
    return new this({ initialFilters, query })
  }

  constructor({ query, initialFilters }) {
    this.query = query
    this._initialFilters = initialFilters
  }

  get label() {
    return this.query
  }

  get nearLabel() {
    return 'you'
  }

  get initialFilters() {
    return this._initialFilters
  }

  toIndexSearchParams() {
    return {
      aroundLatLngViaIP: true,
      hitsPerPage: 15,
      query: this.query,
      restrictSearchableAttributes: this.searchOn,
    }
  }

  toURLSearchParams() {
    return {
      type: this.type,
      query: this.query,
    }
  }
}

export class CoachSelection extends BaseQuerySelection {
  get type() {
    return types.COACH
  }

  get searchOn() {
    return ['coach_name']
  }
}

export class QuerySelection extends BaseQuerySelection {
  get type() {
    return types.QUERY
  }

  get initialFilters() {
    return {}
  }

  get searchOn() {
    return ['facility_name', 'zip', 'city', 'state']
  }
}

export class EmptySelection {
  static fromURLSearchParams() {
    return new EmptySelection()
  }

  get label() {
    return ''
  }

  get type() {
    return types.EMPTY
  }

  get initialFilters() {
    return {}
  }

  get nearLabel() {
    return 'you'
  }

  toIndexSearchParams() {
    return {
      aroundLatLngViaIP: true,
      hitsPerPage: 15,
    }
  }

  toURLSearchParams() {
    return {}
  }
}

const selectionTypeClassMap = setDefaultReturn(
  {
    [types.PLACE]: PlaceSelection,
    [types.FACILITY]: FacilitySelection,
    [types.COACH]: CoachSelection,
    [types.QUERY]: QuerySelection,
    [types.EMPTY]: EmptySelection,
  },
  EmptySelection,
)

export function fromURLSearchParams(urlParams, { initialFilters = {} } = {}) {
  const type = urlParams.get('type') || types.EMPTY
  return selectionTypeClassMap[type].fromURLSearchParams(urlParams, {
    initialFilters,
  })
}
