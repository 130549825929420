import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Divider from '@material-ui/core/Divider'
import Popover from '@material-ui/core/Popover'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from 'lib/auth'
import * as maybe from 'lib/maybe'
import AccountRouterLink from 'components/account-router-link'
import { show } from 'lib/support'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    minWidth: theme.spacing(4),
  },
  menuItemText: {
    color: theme.palette.text.primary,
  },
}))

export default function AccountDropdown() {
  const { user, isLoggedIn, isCoach, isConsumer } = useAuth()
  const classes = useStyles()
  const [isCoachingCenterStudentListVisible] = useFeatureFlags([
    flags.FLAG_FEAT_COACHING_CENTER_STUDENT_LIST_MENU_MANAGEMENT,
  ])

  const [anchorEl, setAnchorEl] = useState(null)

  const toggleAccountMenu = event => {
    setAnchorEl(el => (el ? null : event.currentTarget))
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const avatarContent = maybe
    .of(user)
    .map(({ name, email }) => name || email)
    .map(name => name.charAt(0).toUpperCase())
    .withDefault('U')

  if (!isLoggedIn) return null

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="account-dropdown-menu"
        aria-haspopup="true"
        onClick={toggleAccountMenu}
      >
        <Avatar
          style={{
            width: '36px',
            height: '36px',
            fontSize: '0.75em',
          }}
          src={user?.coach?.coachProfile?.profilePhoto}
        >
          {avatarContent}
        </Avatar>
        <ExpandMoreOutlinedIcon fontSize="small" />
      </IconButton>

      <Popover
        id="account-dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={closeMenu}
      >
        <MenuList autoFocusItem>
          {isCoach ? (
            <>
              {(window.location.href.includes('pga-coach') ||
                window.location.href.includes('junior-league')) && (
                <>
                  <MenuItem
                    onClick={() => {
                      show()
                      closeMenu()
                    }}
                  >
                    <ListItemText classes={{ primary: classes.menuItemText }}>
                      Contact Us
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={'a'}
                    target="_blank"
                    href="https://support.pga.com/en/collections/2683103-pga-members"
                    onClick={closeMenu}
                  >
                    <ListItemText classes={{ primary: classes.menuItemText }}>
                      PGA Coach Help Center
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <OpenInNewOutlinedIcon />
                    </ListItemSecondaryAction>
                  </MenuItem>
                  <Divider />
                </>
              )}
              <AccountDropdownItem
                component={AccountRouterLink}
                path="/account"
                text="Account Details"
                onClick={closeMenu}
              />
              <SensitiveInfoWrapper>
                <AccountDropdownItem
                  component={AccountRouterLink}
                  path="/account/payments"
                  text="Payments"
                  onClick={closeMenu}
                />
              </SensitiveInfoWrapper>
              {(user.familyCupSignedUp || user.jrlSignedUp) && (
                <AccountDropdownItem
                  component={AccountRouterLink}
                  path="/account/requirements"
                  text="Requirements"
                  onClick={closeMenu}
                />
              )}
              {user.isAcademyOwner && (
                <AccountDropdownItem
                  component={AccountRouterLink}
                  path="/account/academy-coaches"
                  text="Manage Coaching Center Coaches"
                  onClick={closeMenu}
                />
              )}
              {user.isAcademyOwner && (
                <AccountDropdownItem
                  component={AccountRouterLink}
                  path="/account/bay-reservations"
                  text="Manage Bay Rentals"
                  onClick={closeMenu}
                />
              )}
              {isCoachingCenterStudentListVisible && user.isAcademyOwner && (
                <AccountDropdownItem
                  component={AccountRouterLink}
                  path="/account/coaching-center"
                  text="Manage Coaching Center Students"
                  onClick={closeMenu}
                />
              )}
            </>
          ) : null}
          {isConsumer ? (
            <>
              <AccountDropdownItem
                component={AccountRouterLink}
                path="/account/payments/transactions"
                text="Transactions"
                onClick={closeMenu}
              />
              <AccountDropdownItem
                component={AccountRouterLink}
                path="/account/students"
                text="Accounts"
                onClick={closeMenu}
              />
            </>
          ) : null}
          <MenuItem component={RouterLink} to="/logout" onClick={closeMenu}>
            <ListItemText classes={{ primary: classes.menuItemText }}>
              Logout
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  )
}

function AccountDropdownItem({ component, path, text, onClick }) {
  const classes = useStyles()
  return (
    <MenuItem component={component} to={path} onClick={onClick}>
      <ListItemText classes={{ primary: classes.menuItemText }}>
        {text}
      </ListItemText>
    </MenuItem>
  )
}
