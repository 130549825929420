import algoliasearch from 'algoliasearch'
import { ALGOLIA_APP_ID, ALGOLIA_API_KEY } from 'env'

const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)
const usCitiesIndex = client.initIndex('us_cities')

export const placesQuery = async query => {
  const { hits } = await usCitiesIndex.search(query, {
    hitsPerPage: 5,
  })

  const results = (hits || []).map(hit => ({
    locale_names: [hit.city],
    administrative: [hit.state],
    matchLength: hit.city.length,
    _geoloc: hit._geoloc,
  }))

  return { hits: results }
}
