import { useState } from 'react'
import { useHistory } from 'react-router-dom'

export const useStepNavigation = ({
  isEditMode,
  report,
  onNext,
  onBack,
  isDirty,
  handleSubmit,
  handleNext,
}) => {
  const [showSaveModal, setShowSaveModal] = useState(false)
  const history = useHistory()

  const handleNavigation = (isBack = false, step = null) => {
    if (isBack) {
      if (isEditMode && step === 'coaching-goals') {
        // On edit mode, we go back to the report detail
        history.push(`/pga-coach/reports/business-insights/${report.id}`)
        return
      }
      // On create mode, we go back to the previous step
      if (!isEditMode || (isEditMode && step !== 'coaching-goals')) {
        onBack()
        return
      }
    }

    // For forward navigation
    if (!isEditMode) {
      handleSubmit()
      return
    }

    if (isDirty) {
      setShowSaveModal(true)
    } else {
      onNext()
    }
  }

  return {
    showSaveModal,
    setShowSaveModal,
    handleNavigation,
  }
}
