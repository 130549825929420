import React from 'react'
import { Button, Hidden, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

const useStyles = makeStyles(theme => ({
  searchResultsFor: {
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 20,
    marginBottom: theme.spacing(4),
    marginTop: 0,
  },

  container: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },

  viewAllContainer: {
    display: 'flex',

    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-end',
    },
  },

  viewAll: {
    color: theme.palette.text.secondary,
    flex: 1,
    fontSize: 13,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },

    [theme.breakpoints.up('sm')]: {
      flex: 'initial',
      fontSize: 14,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
    },
  },
}))

const createContainer = ({
  query,
  havePrograms,
  onViewAllMatching,
  buttonText = 'VIEW ALL MATCHING PROGRAMS',
}) => ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Paper {...props} classes={{ root: classes.container }}>
      {query.trim() && (
        <Hidden smDown implementation="css">
          <Typography variant="h5" className={classes.searchResultsFor}>
            search results for &quot;{query}&quot;
          </Typography>
        </Hidden>
      )}

      {children}

      {havePrograms && (
        <div className={classes.viewAllContainer}>
          <Button
            className={classes.viewAll}
            endIcon={<ArrowRightAltIcon />}
            // Using onMouseDown becuase onClick fires after the onBlur event
            // from the autocomplete input, causing the results to close
            // before onClick can trigger
            onMouseDown={() => onViewAllMatching()}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </Paper>
  )
}

export default createContainer
