import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@material-ui/core/Button'

const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, borderBottom: '1px solid #E0E0E0', paddingX: '2rem' }}>
        Save Changes
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: '1rem 2rem' }}>
        Are you sure you want to proceed? Saving these changes will modify your report.
      </DialogContent>
      <DialogActions sx={{ padding: '1rem 2rem' }}>
        <Button onClick={onClose} variant="outlined">
          CANCEL
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
        >
          SAVE CHANGES
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
