import React from 'react'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import { useIsMobile } from 'lib/hooks'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const BookingDetails = ({ classes }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    style={{ padding: '20px 0' }}
  >
    <Grid container direction="column">
      <Skeleton
        variant="rect"
        width={180}
        height={21}
        className={classes.rounded}
      />

      <Skeleton
        variant="rect"
        height={50}
        className={classes.rounded}
        style={{ margin: '1.25rem 0' }}
      />
      <Skeleton
        variant="rect"
        width={220}
        height={21}
        className={classes.rounded}
      />
    </Grid>
    <Divider style={{ marginTop: '2.75rem' }} />
    <Skeleton
      height={80}
      variant="rect"
      style={{ marginTop: '2rem', borderRadius: '.5rem' }}
    />
  </Box>
)
const TransactionSummarySkeleton = ({ classes }) => (
  <>
    <Skeleton
      height={21}
      variant="rect"
      className={classes.rounded}
      style={{ marginBottom: '1.25rem' }}
    />
    <Skeleton
      height={80}
      variant="rect"
      className={classes.rounded}
      style={{ marginBottom: '1.25rem' }}
    />
  </>
)
const BookingActionsSummary = ({ classes }) => (
  <Grid container item justifyContent="space-between">
    <Grid item xs={5}>
      <Skeleton
        variant="rect"
        height={50}
        className={classes.rounded}
        style={{ marginTop: '1.25rem' }}
      />
    </Grid>
    <Grid item xs={5}>
      <Skeleton
        variant="rect"
        height={50}
        className={classes.rounded}
        style={{ marginTop: '1.25rem' }}
      />
    </Grid>
  </Grid>
)

const BookingConfirmationSkeleton = () => {
  const useStyles = makeStyles({
    rounded: {
      borderRadius: '1rem',
    },
    roundeOffset: {
      borderRadius: '1.25rem',
      marginBottom: '.75rem',
    },
  })
  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <Container style={{ margin: '1.25rem 0' }}>
      {/* Booking details section */}
      <Box>
        {isMobile ? (
          <Box>
            <Grid container item direction="row" alignItems="center">
              <Skeleton
                variant="rect"
                height={28}
                className={classes.roundeOffset}
              />
            </Grid>
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              style={{ marginTop: '1.5rem' }}
            >
              <Skeleton
                variant="rect"
                width={127}
                height={21}
                className={classes.rounded}
              />
            </Grid>
            <Grid
              container
              direction="column"
              item
              style={{ marginTop: '1.5rem' }}
            >
              <Grid item xs={10}>
                <Skeleton
                  variant="rect"
                  height={60}
                  className={classes.rounded}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid container>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <Skeleton
                  variant="rect"
                  height={35}
                  className={classes.rounded}
                />
              </Grid>
              <Grid item xs={11} style={{ marginTop: '1.25rem' }}>
                <Skeleton
                  variant="rect"
                  height={14}
                  className={classes.rounded}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      <Divider style={{ marginTop: '2.75rem' }} />
      <Grid
        container
        style={{ marginTop: '2rem' }}
        justifyContent={isMobile ? 'flex-start' : 'center'}
      >
        <Grid item xs={12} sm={6} lg={4}>
          <TransactionSummarySkeleton classes={classes} />
          {new Array(2).fill().map((_, i) => (
            <Grid container spacing={2} key={`booking-details-skeleton-${i}`}>
              <Grid item xs={12} md={6}>
                <Divider />
                <BookingDetails classes={classes} />
              </Grid>
            </Grid>
          ))}
          <BookingActionsSummary classes={classes} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default BookingConfirmationSkeleton
