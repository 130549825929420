export const formatCurrencyInput = value => {
  const cleanValue = value?.replace(/[^\d.]/g, '')

  const parts = cleanValue.split('.')
  let number = parts[0]
  if (parts.length > 1) {
    number = `${parts[0]}.${parts[1]}`
  }

  if (value.startsWith('.')) {
    number = `0${number}`
  }

  if (value.includes('.')) {
    return number
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
  }).format(parseFloat(number || 0))
}

export const formatFromIntToCurrency = value => {
  if (value === null || value === undefined) return ''

  // If this is a number, convert it to string
  const stringValue = typeof value === 'number' ? value.toString() : value

  // Remove any character that is not a number or decimal point
  const cleanValue = stringValue.replace(/[^\d.]/g, '')

  // Convert to number and format
  const number = parseFloat(cleanValue)
  if (isNaN(number)) return ''

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
}

export const convertToCents = value => {
  if (!value) return 0
  const number = parseFloat(value.toString().replace(/,/g, ''))
  return Math.round(number * 100)
}

export const formatNumberWithCommas = number => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0'
}
