import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import useStyles from './styles'
import InfoTextContainer from 'components/info-text-container'
import { formatFromIntToCurrency } from 'utils/number'

const CoachInsightsProfitChart = ({ lineChartData, title, value, infoText }) => {
  const classes = useStyles()

  if (!lineChartData || lineChartData.length === 0) {
    return <p>No data available</p>
  }

  const allValues = lineChartData.flatMap(item =>
    Object.keys(item).filter(key => key !== 'month').map(key => item[key]),
  )

  const maxValue = Math.max(...allValues)
  const minValue = Math.min(...allValues)
  const yDomain = [Math.round(minValue / 3), Math.round(maxValue * 1.2)]

  const colors = [
    '#9E9E9E',
    '#3268A7',
    '#043362',
    '#007AFF',
  ]

  const formatKey = (key) => {
    return key.replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^./, str => str.toUpperCase())
  }

  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <p className={classes.description}>
          {title}
        </p>
        <p className={classes.value}>
          {value}
        </p>
        {infoText && (
          <InfoTextContainer style={{ margin: '.5rem 0 1rem 0', width: 'fit-content' }}>
            <span>
              {infoText}
            </span>
          </InfoTextContainer>
        )}
        <ResponsiveContainer width="100%" height={140}>
          <LineChart
            data={lineChartData.map(item => Object.fromEntries(Object.entries(item)))}
            margin={{ left: -15 }}
          >
            <CartesianGrid />
            <XAxis dataKey="month" axisLine={false} tickLine={false} tick={{ fontSize: 12 }} />
            <YAxis domain={yDomain} axisLine={false} tickLine={false} tick={{ fontSize: 12 }} />
            <Tooltip formatter={(value) => formatFromIntToCurrency(Number(value).toFixed(2))} />
            <Legend wrapperStyle={{ fontSize: '14px' }} />
            {Object.keys(lineChartData[0]).filter(key => key !== 'month')?.map((key, index) => (
              key !== 'month' &&
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                name={formatKey(key)}
                stroke={colors[index - 1 % colors.length]}
                dot={{ fill: colors[index - 1 % colors.length] }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default CoachInsightsProfitChart
