import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import PromotedProfileCaption from 'components/promoted-profile-caption'
import CoachProfileCard from 'components/coach-profile-card'
import CoachProfileAnalytics from './coach-profile-analytics'
import React from 'react'
import { useMutation } from '@apollo/client'
import { ToggleCoachStatus } from './query'
import { useStyles } from './styles'
import EmptyPage from 'components/empty-page-state'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import { gtmEvent } from 'lib/gtm'
import { useHistory } from 'react-router-dom'

const CoachProfileView = ({ coachProfile, facilities, refetch }) => {
  const classes = useStyles()
  const history = useHistory()

  const [toggleCoachStatus] = useMutation(ToggleCoachStatus, {
    onCompleted: refetch,
  })

  const redirectToCreateProfile = () => {
    history.push('/pga-coach/profile/new')
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'coach-profile',
      formAction: 'clicked-create-coach-profile-button',
    })
  }

  return coachProfile != null ? (
    <Grid container spacing={1}>
      <Grid container className={classes.coachProfileCardsContainer}>
        <Grid item xs={12} lg={12} xl={12}>
          <Box>
            <PromotedProfileCaption
              coachProfile={coachProfile}
              facilities={facilities}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <Box>
            <CoachProfileCard
              coachProfile={coachProfile}
              toggleCoachStatus={toggleCoachStatus}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          xl={12}
          className={classes.analyticsCardContainer}
        >
          <Grid item xs={12} container justifyContent="center">
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <CoachProfileAnalytics />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <EmptyPage
      actionFn={redirectToCreateProfile}
      IconComp={() => <PersonOutlineOutlinedIcon fontSize="large" />}
      title="Coach Profile"
      caption="Your PGA Coach Profile will appear here once completed."
      buttonText="Create Coach Profile"
    />
  )
}

export default CoachProfileView
