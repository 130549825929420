import React from 'react'
import { Box, Typography } from '@material-ui/core'
import CelebrationIcon from '@mui/icons-material/Celebration'
import useStyles from './styles'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const Confirmation = ({ report }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleViewInsights = () => {
    const reportId = report?.id || ''
    history.push(`/pga-coach/reports/business-insights/${reportId}`)
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>
        <CelebrationIcon className={classes.icon} />
      </Box>

      <Typography className={classes.title} variant="h4" component="h1" gutterBottom>
        Success!
      </Typography>

      <Typography variant="body1" className={classes.description}>
        You have successfully created your insight dashboards, and you can access your personalized reporting dashboard to review your performance insights and essential metrics
      </Typography>

      <div className={classes.buttonsContainer}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleViewInsights}
        >
          VIEW MY INSIGHTS DASHBOARD
        </Button>

        <Button
          variant="text"
          color="primary"
          onClick={() => history.push('/pga-coach/reports')}
        >
          BACK TO REPORTS
        </Button>
      </div>
    </Box>
  )
}

Confirmation.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string,
  }),
}

Confirmation.defaultProps = {
  report: { id: '' },
}

export default Confirmation
