import auth0 from './client'

const getToken = async () => {
  try {
    const accessToken = await auth0.getTokenSilently()
    return accessToken
  } catch (error) {
    const skippedErrors = [
      'login_required',
      'mfa_required',
      'missing_refresh_token',
      'invalid_grant',
    ]
    if (!skippedErrors.includes(error.error)) {
      console.error(error)
      window.rg4js &&
        window.rg4js('send', {
          error,
          tags: ['auth'],
        })
      throw error
    }
    return null
  }
}

export { getToken }
