import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { NavLink, Link as RouterLink } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check'
import DefaultDashboard from './default-dashboard'
import { useCalcProgress } from 'lib/hooks'
import get from 'lodash/get'
import Dialog from 'components/onboarding-dialog'
import SettingsBox from 'components/coach-settings-box'
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined'
import Grid from '@material-ui/core/Grid'
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import { PrivateSessionIcon } from 'components/icons/refreshed-icons'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    margin: '0 60px 60px',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      margin: '10px',
    },
  },
  list: {
    border: '1px solid #E0E0E0',
    borderRadius: '16px',
    padding: 0,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  listItem: {
    borderBottom: '1px solid #E0E0E0',
    minHeight: '98px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    '& > div': {
      opacity: 0.5,
    },
  },
  icon: {
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    opacity: '1 !important',
  },
  action: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  progress: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    color: '#3268A7',
    marginBottom: '30px',
  },
  progressBar: {
    width: 'calc(100% - 60px)',
    height: '12px',
    borderRadius: '4px',
    backgroundColor: 'rgba(158, 171, 186, 0.6)',
  },
  bar: {
    backgroundColor: '#3268A7',
  },
  bgIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.selected,
    borderRadius: 70,
    minWidth: 70,
    minHeight: 70,
  },
  mainWrapper: {
    textDecoration: 'none',
  },
}))

const ScheduleDialog = ({ dialogOpen, closeDialog, listItems }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      heading="Add to your Schedule"
      text="What type of offering would you like to add to your calendar?"
      scheduleDialog
    >
      <Box mt={3}>
        <Grid container spacing={2}>
          {listItems.map((block, index) => (
            <Grid item xs={12} key={index}>
              <RouterLink className={classes.mainWrapper} to={block.link}>
                <SettingsBox
                  icon={<div className={classes.bgIcon}>{block.icon}</div>}
                  title={block.title}
                  subtitle={block.subtitle}
                  action={<ChevronRightIcon className={classes.action} />}
                />
              </RouterLink>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  )
}

const ListItemComponent = ({ children, item, index, className }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const closeDialog = () => setDialogOpen(false)

  return item.link ? (
    <ListItem
      key={index}
      className={className}
      button
      component={NavLink}
      to={item.link}
      aria-disabled={!item.available}
      data-testid={item.step}
    >
      {children}
    </ListItem>
  ) : (
    <>
      <ListItem
        key={index}
        className={className}
        button
        onClick={() => setDialogOpen(true)}
        aria-disabled={!item.available}
        data-testid={item.step}
      >
        {children}
      </ListItem>

      <ScheduleDialog
        listItems={item.scheduleListItems}
        dialogOpen={dialogOpen}
        closeDialog={closeDialog}
      />
    </>
  )
}

const buildListItems = coachOnboardingProgress => {
  return [
    {
      step: 'create-coach-profile',
      icon: <PersonOutlineOutlinedIcon />,
      primary: 'Create a PGA Coach Profile',
      secondary:
        "Secure your place in PGA.com's coaching directory and make it a breeze for golfers to find and book sessions with you. Not ready to go public? No problem, you can hide your profile too.",
      link: '/pga-coach/onboarding/profile/new',
      available: coachOnboardingProgress.coachProfileCreated === null,
    },
    {
      step: 'add-facility',
      icon: <RoomOutlinedIcon />,
      primary: 'Set your Location(s)',
      secondary:
        'Let potential students easily locate you by adding all the facilities where you coach to your profile, and remember to set any private clubs as "private.',
      link: '/pga-coach/onboarding/facilities/new',
      available: coachOnboardingProgress.facilityAdded === null,
    },
    {
      step: 'add-availability',
      icon: <EventAvailableOutlinedIcon />,
      primary: 'Set your Working Hours',
      secondary:
        'Only available certain days of the week? Prefer to block off your afternoons? Make sure to set up your working hours before adding Lesson Types (1:1 lessons).',
      link: '/pga-coach/onboarding/availability',
      available: coachOnboardingProgress.availabilityAdded === null,
    },
    {
      step: 'add-schedule',
      icon: <CalendarTodayOutlinedIcon />,
      primary: 'Add to your Schedule',
      secondary:
        'Do you prefer teaching in 1:1 lessons or in groups? PGA Coach allows you to set up offerings that fit any coaching preference!',
      available: coachOnboardingProgress.schedulableCreated === null,
      scheduleListItems: [
        {
          title: 'Booking Types',
          subtitle:
            'Booking Types are offerings for **individuals**. Some examples: private coaching sessions, club fittings, swing evaluations, and lesson packs.',
          icon: <LibraryBooksOutlinedIcon />,
          link: '/pga-coach/onboarding/bookings/booking-types/new',
        },
        {
          title: 'Group Events',
          subtitle:
            'Group Events are offerings for **groups**, like after school clinics, Get Golf Ready, supervised practice, and boot camps.',
          icon: <CelebrationOutlinedIcon />,
          link: '/pga-coach/onboarding/bookings/events/new',
        },
      ],
    },
  ]
}

const advancedListItems = [
  {
    icon: <PrivateSessionIcon color="rgba(0, 0, 0, 0.87)" size={24} />,
    primary: 'ADM',
    secondary:
      'Developing fundamental skills for all sports by providing a "sport pathway" for coaches and athletes to get involved and become passionate about sports for their lifetime.',
    link:
      'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/reports/certification/',
    available: true,
  },
  {
    icon: <SchoolOutlinedIcon />,
    primary: 'Training',
    secondary:
      'PGA Coach offers a training center featuring diverse courses designed to elevate your coaching journey and empower you to become the best coach possible.',
    link:
      'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/trainingCenter/',
    available: true,
  },
]
const Dashboard = ({ data }) => {
  const classes = useStyles()
  const { coachOnboardingProgress } = get(data, 'currentUser.attributes')

  const progress = useCalcProgress(coachOnboardingProgress)
  const listItems = buildListItems(coachOnboardingProgress)

  return progress === 100 ? (
    <DefaultDashboard
      hasGroupEventsOrLessonTypes={coachOnboardingProgress.schedulableCreated}
      slug={data?.currentUser?.attributes?.coachProfile?.slug}
      isAdmCertified={
        data?.currentUser?.attributes?.pgaMembership?.admCertified
      }
    />
  ) : (
    <Box className={classes.container}>
      <Box>
        <Typography
          variant="h4"
          style={{ fontWeight: 500, marginBottom: '20px' }}
          color="primary"
        >
          Welcome to PGA Coach
        </Typography>
        <Typography variant="body1" style={{ marginBottom: '20px' }}>
          Welcome to PGA Coach! Get started by completing our easy 4-step
          onboarding process!
        </Typography>
      </Box>
      <Box className={classes.progress}>
        <LinearProgress
          color="primary"
          variant="determinate"
          value={progress}
          classes={{
            root: classes.progressBar,
            barColorPrimary: classes.bar,
          }}
        />
        <Typography variant="h6">{progress} %</Typography>
      </Box>
      <Box>
        <Box px={3} py={1}>
          <Typography variant="h6">Let’s get you set up!</Typography>
        </Box>
        <List className={classes.list}>
          {listItems.map((item, index) => (
            <ListItemComponent
              key={index}
              className={`${classes.listItem} ${!item.available &&
                classes.disabled}`}
              to={item.link}
              item={item}
            >
              <ListItemIcon className={classes.icon}>
                {item.available ? (
                  item.icon
                ) : (
                  <CheckIcon style={{ color: '#3268A7' }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" color="textPrimary">
                    {item.primary}
                  </Typography>
                }
                secondary={
                  item.available && (
                    <Typography variant="body2" color="textSecondary">
                      {item.secondary}
                    </Typography>
                  )
                }
              />
              {item.available && (
                <ChevronRightIcon className={classes.action} />
              )}
            </ListItemComponent>
          ))}
        </List>
      </Box>
      <Box mt={4}>
        <Box px={3} py={1}>
          <Typography variant="h6">Advanced Setup:</Typography>
        </Box>
        <List className={classes.list}>
          {advancedListItems.map((item, index) => (
            <ListItem
              key={index}
              className={`${classes.listItem} ${!item.available &&
                classes.disabled}`}
              component="a"
              target="_blank"
              href={item.link}
            >
              <ListItemIcon className={classes.icon}>
                {item.available ? (
                  item.icon
                ) : (
                  <CheckIcon style={{ color: '#3268A7' }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" color="textPrimary">
                    {item.primary}
                  </Typography>
                }
                secondary={
                  item.available && (
                    <Typography variant="body2" color="textSecondary">
                      {item.secondary}
                    </Typography>
                  )
                }
              />
              <ChevronRightIcon className={classes.action} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default Dashboard
