import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  memberNumberInput: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(4),
    },
  },
}))

const ConfirmMemberNumberInput = ({
  memberNumber,
  setMemberNumber,
  memberNumberError,
  fieldLength,
}) => {
  const styles = useStyles()

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <TextField
        fullWidth
        label="Member Number"
        required
        name="memberNumber"
        error={!!memberNumberError}
        helperText={memberNumberError}
        onChange={e => setMemberNumber(e.target.value)}
        type="number"
        value={memberNumber}
        variant="outlined"
        className={styles.memberNumberInput}
        onInput={e => {
          e.target.value = e.target.value
            .replace(/[^0-9]/g, '')
            .slice(0, fieldLength)
        }}
      />
    </Grid>
  )
}

export default ConfirmMemberNumberInput
