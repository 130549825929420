import React, { useEffect, useState } from 'react'
import {
  Typography,
  TextField,
  Box,
  Grid,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { formatCurrencyInput, formatFromIntToCurrency, convertToCents } from 'utils/number'
import FormLoadingSkeleton from '../loading-skeleton'
import { useMutation, useQuery } from '@apollo/client'
import { UpsertFacilityConstantMutation, GetFacilityConstantsQuery } from '../query'
import { useSnackbar } from 'notistack'
import ConfirmationDialog from '../save-changes-modal'
import { useStepNavigation } from 'lib/hooks/useStepNavigation'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'

const FacilityMetrics = ({ loading: parentLoading, onNext, onBack, report, isEditMode }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [upsertFacilityConstant] = useMutation(UpsertFacilityConstantMutation)
  const [isLoading, setIsLoading] = useState(false)

  // Query to get facility constants when editing
  const { data: facilityConstantsData, loading: facilityConstantsLoading } = useQuery(
    GetFacilityConstantsQuery,
    {
      variables: { reportId: report?.id },
      skip: !report?.id,
    },
  )

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      costOfMembership: '',
      avgFoodAndBevSpend: '',
      avgMerchSpend: '',
      avgDrivingRangeSpend: '',
      avgOtherSpend: '',
    },
    mode: 'onChange',
  })

  // Effect to load previous data if available
  useEffect(() => {
    if (facilityConstantsData?.facilityConstants) {
      const constants = facilityConstantsData.facilityConstants
      setValue('costOfMembership', formatFromIntToCurrency(constants.membershipCostInCents / 100))
      setValue('avgFoodAndBevSpend', formatFromIntToCurrency(constants.avgFbSpendInCents / 100))
      setValue('avgMerchSpend', formatFromIntToCurrency(constants.avgMerchandiseSpendInCents / 100))
      setValue('avgDrivingRangeSpend', formatFromIntToCurrency(constants.avgDrivingRangeSpendInCents / 100))
      setValue('avgOtherSpend', formatFromIntToCurrency(constants.avgOtherSpendInCents / 100))
    }
  }, [facilityConstantsData, setValue])

  const formValues = {
    costOfMembership: watch('costOfMembership'),
    avgFoodAndBevSpend: watch('avgFoodAndBevSpend'),
    avgMerchSpend: watch('avgMerchSpend'),
    avgDrivingRangeSpend: watch('avgDrivingRangeSpend'),
    avgOtherSpend: watch('avgOtherSpend'),
  }

  const handleCurrencyChange = (name) => (event) => {
    const formattedValue = formatCurrencyInput(event.target.value)
    setValue(name, formattedValue, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const handleBlur = (name) => (event) => {
    if (event.target.value) {
      const formattedValue = formatFromIntToCurrency(event.target.value)
      setValue(name, formattedValue, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }

  const handleNext = async (data) => {
    setIsLoading(true)
    try {
      if (isDirty) {
        await upsertFacilityConstant({
          variables: {
            facilityId: report.facilityId,
            reportId: report.id,
            input: {
              membershipCostInCents: convertToCents(data.costOfMembership),
              avgFbSpendInCents: convertToCents(data.avgFoodAndBevSpend),
              avgMerchandiseSpendInCents: convertToCents(data.avgMerchSpend),
              avgDrivingRangeSpendInCents: convertToCents(data.avgDrivingRangeSpend),
              avgOtherSpendInCents: convertToCents(data.avgOtherSpend),
            },
          },
        })
      }
      onNext()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const { showSaveModal, setShowSaveModal, handleNavigation } = useStepNavigation({
    isEditMode,
    report,
    onNext,
    onBack,
    isDirty,
    handleSubmit,
    handleNext,
  })

  const onSubmit = (data) => {
    if (isEditMode && isDirty) {
      setShowSaveModal(true)
      return
    }
    handleNext(data)
  }

  if (parentLoading || facilityConstantsLoading) {
    return <FormLoadingSkeleton type="facility" />
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontSize: '34px', lineHeight: '42px', fontWeight: 500 }}
        >
          Facility Metrics
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: 3, fontSize: '16px', lineHeight: '24px', color: '#0000008A' }}
        >
          Enter your facility or club metrics, averages should be calculated per day and per student.
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextField
            {...register('costOfMembership')}
            label="Cost of Membership"
            helperText={errors.costOfMembership?.message || 'Monthly dues, initiation, etc.'}
            value={formValues.costOfMembership || ''}
            onChange={handleCurrencyChange('costOfMembership')}
            onBlur={handleBlur('costOfMembership')}
            InputProps={{
              startAdornment: <span>$</span>,
            }}
            error={!!errors.costOfMembership}
            fullWidth
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('avgFoodAndBevSpend')}
                label="Avg Food and Beverage Spend"
                value={formValues.avgFoodAndBevSpend || ''}
                onChange={handleCurrencyChange('avgFoodAndBevSpend')}
                onBlur={handleBlur('avgFoodAndBevSpend')}
                InputProps={{
                  startAdornment: <span>$</span>,
                }}
                error={!!errors.avgFoodAndBevSpend}
                helperText={errors.avgFoodAndBevSpend?.message}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('avgMerchSpend')}
                label="Avg Merchandise Spend"
                value={formValues.avgMerchSpend || ''}
                onChange={handleCurrencyChange('avgMerchSpend')}
                onBlur={handleBlur('avgMerchSpend')}
                InputProps={{
                  startAdornment: <span>$</span>,
                }}
                error={!!errors.avgMerchSpend}
                helperText={errors.avgMerchSpend?.message}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('avgDrivingRangeSpend')}
                label="Avg Driving Range Spend"
                value={formValues.avgDrivingRangeSpend || ''}
                onChange={handleCurrencyChange('avgDrivingRangeSpend')}
                onBlur={handleBlur('avgDrivingRangeSpend')}
                InputProps={{
                  startAdornment: <span>$</span>,
                }}
                error={!!errors.avgDrivingRangeSpend}
                helperText={errors.avgDrivingRangeSpend?.message}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('avgOtherSpend')}
                label="Avg Misc Spend"
                value={formValues.avgOtherSpend || ''}
                onChange={handleCurrencyChange('avgOtherSpend')}
                onBlur={handleBlur('avgOtherSpend')}
                InputProps={{
                  startAdornment: <span>$</span>,
                }}
                error={!!errors.avgOtherSpend}
                helperText={errors.avgOtherSpend?.message}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 2 }}>
          <Button
            onClick={() => handleNavigation(true)}
            variant="outlined"
            color="primary"
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={16} />}
          >
            Save and Continue
          </Button>
        </Box>
      </form>

      <ConfirmationDialog
        open={showSaveModal}
        onClose={() => {
          setShowSaveModal(false)
        }}
        onConfirm={() => {
          handleSubmit(handleNext)()
          setShowSaveModal(false)
        }}
      />
    </>
  )
}

export default FacilityMetrics
