import environment from 'environment'
// env.js
// common module for all environment variables imported by the application

export const NODE_ENV = environment.NODE_ENV || process.env.NODE_ENV
export const PUBLIC_URL = environment.PUBLIC_URL

// hostname for backend graphql service
export const API_ROOT = environment.REACT_APP_API_ROOT

// root url for Flagr service
export const FLAGR_API_ROOT = environment.REACT_APP_FLAGR_API_ROOT

// hostname for PGA.com site
export const PGA_COM_HOST = environment.REACT_APP_PGA_COM_HOST

// hostname for my.pga.com site
export const MY_PGA_COM_HOST = environment.REACT_APP_MY_PGA_COM_HOST

// cloudinary integration
export const CLOUDINARY_CLOUDNAME = environment.REACT_APP_CLOUDINARY_CLOUDNAME
export const CLOUDINARY_UPLOAD_PRESET =
  environment.REACT_APP_CLOUDINARY_UPLOAD_PRESET
export const CLOUDINARY_UPLOAD_PRESET_LARGE =
  environment.REACT_APP_CLOUDINARY_UPLOAD_PRESET_LARGE

// algolia integration
export const ALGOLIA_APP_ID = environment.REACT_APP_ALGOLIA_APP_ID
export const ALGOLIA_API_KEY = environment.REACT_APP_ALGOLIA_API_KEY
export const FC_EVENT_SEARCH_INDEX = environment.REACT_APP_FC_PROGRAM_INDEX_NAME
export const JRL_EVENT_SEARCH_INDEX =
  environment.REACT_APP_JRL_PROGRAM_INDEX_NAME
export const JRL_PLAY_DAY_INDEX_NAME =
  environment.REACT_APP_JRL_PLAY_DAY_INDEX_NAME
export const COACH_ACTIVITIES_INDEX_NAME =
  environment.REACT_APP_COACH_ACTIVITIES_INDEX_NAME
export const GROUP_EVENTS_INDEX_NAME =
  environment.REACT_APP_GROUP_EVENTS_INDEX_NAME

// stripe integration
export const STRIPE_KEY = environment.REACT_APP_STRIPE_KEY

// background checks
export const MANAGE_BACKGROUND_CHECK_URL =
  environment.REACT_APP_MANAGE_BACKGROUND_CHECK_URL
export const BACKGROUND_CHECK_EXPIRATION_IN_YEARS = parseInt(
  environment.REACT_APP_BACKGROUND_CHECK_EXPIRATION_IN_YEARS || 2,
  10,
)
export const BACKGROUND_CHECK_START_RENEWAL_IN_MONTHS = parseInt(
  environment.REACT_APP_BACKGROUND_CHECK_START_RENEWAL_IN_MONTHS || 3,
  10,
)

// family-cup and junior leage
export const FAMILY_GOLF_EMAIL = environment.REACT_APP_FAMILY_GOLF_EMAIL
export const FC_MARKETING_SITE_URL = environment.REACT_APP_FC_MARKETING_SITE_URL
export const FG_MARKETING_SITE_URL = environment.REACT_APP_FG_MARKETING_SITE_URL
export const JRL_MARKETING_SITE_URL =
  environment.REACT_APP_JRL_MARKETING_SITE_URL

// google API integrations
export const GOOGLE_CLIENT_ID = environment.REACT_APP_GOOGLE_CLIENT_ID

export const JOTFORM_TEAM_KIT_URL = environment.REACT_APP_JOTFORM_TEAM_KIT_URL

export const RECAPTCHA_V2_KEY = environment.REACT_APP_RECAPTCHA_V2_KEY

export const STREAM_API_KEY = environment.REACT_APP_STREAM_API_KEY

// PGA support pages
export const JRL_RESOURCE_CENTER_URL =
  environment.REACT_APP_JRL_RESOURCE_CENTER_URL

// My PGA mobile app
export const MY_PGA_GOOGLE_PLAY_STORE =
  environment.REACT_APP_MY_PGA_GOOGLE_PLAY_STORE
export const MY_PGA_APPLE_APP_STORE =
  environment.REACT_APP_MY_PGA_APPLE_APP_STORE

export const APP_VERSION = environment.REACT_APP_VERSION
export const APP_ENVIRONMENT = environment.REACT_APP_ENVIRONMENT

// Datadog credentials
export const DD_APP_ID = environment.REACT_APP_DD_APP_ID
export const DD_CLIENT_TOKEN = environment.REACT_APP_DD_CLIENT_TOKEN

// Auth0 integration
export const AUTH0_DOMAIN = environment.REACT_APP_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = environment.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_AUDIENCE = environment.REACT_APP_AUTH0_AUDIENCE

// Amplitude integration
export const AMPLITUDE_API_KEY = environment.REACT_APP_AMPLITUDE_API_KEY
