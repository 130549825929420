import React from 'react'
import { Skeleton, Grid } from '@mui/material'

export const FieldSkeleton = ({ height = 56 }) => (
  <Skeleton variant="rectangular" height={height} sx={{ borderRadius: 1 }} />
)

const FormLoadingSkeleton = ({ type }) => {
  switch (type) {
    case 'general':
      return (
        <>
          <Skeleton variant="text" width="60%" height={42} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="90%" height={24} sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            {[...Array(3)].map((_, i) => (
              <Grid item xs={12} key={i}>
                <FieldSkeleton />
              </Grid>
            ))}
          </Grid>
        </>
      )

    case 'coaching':
      return (
        <>
          <Skeleton variant="text" width="60%" height={42} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="90%" height={24} sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            {[...Array(3)].map((_, i) => (
              <Grid item xs={12} key={i}>
                <FieldSkeleton />
              </Grid>
            ))}
          </Grid>
        </>
      )

    case 'facility':
      return (
        <>
          <Skeleton variant="text" width="60%" height={42} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="90%" height={24} sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldSkeleton />
            </Grid>
            {[...Array(4)].map((_, i) => (
              <Grid item xs={12} sm={6} key={i}>
                <FieldSkeleton />
              </Grid>
            ))}
          </Grid>
        </>
      )

    case 'monthly':
      return (
        <>
          <Skeleton variant="text" width="60%" height={42} sx={{ mb: 1 }} />
          <Skeleton variant="text" width="90%" height={24} sx={{ mb: 3 }} />
          <Skeleton variant="rectangular" height={48} sx={{ borderRadius: 1, mb: 3 }} />
          <Grid container spacing={3}>
            {[...Array(12)].map((_, i) => (
              <Grid item xs={12} sm={6} key={i}>
                <FieldSkeleton />
              </Grid>
            ))}
          </Grid>
        </>
      )

    default:
      return null
  }
}

export default FormLoadingSkeleton
