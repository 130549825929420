import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useIsMobile } from 'lib/hooks'
import PropTypes from 'prop-types'
import {
  toFullTextDayDateFromVisibleTimezone,
  toFullTextDayDateWithTimezone,
  getLessonDurationTextWithTimezone,
} from 'utils/dateUtils'
import { SavingsIcon } from 'components/icons/refreshed-icons'
import { formatCurrency } from '../../schedule/utils'
import { estimateCoachingCenterSalesTaxInCents } from 'lib/utils/number'

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: 0,
  },
  root: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: '1rem',
  },
  attributes: {
    color: theme.palette.grey.dark,
    fontWeight: 600,
    fontSize: '1rem',
  },
  icons: {
    color: theme.palette.secondary.dark,
  },
  avatar: {
    border: '2px solid rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 1px 1px',
    width: '86px',
    height: '86px',
    marginTop: '0.25rem',
    color: '#fff',
    backgroundColor: '#d8d8d8',
  },
  mobileAvatar: {
    border: '2px solid rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 1px 1px',
    width: '3.3rem',
    height: '3.3rem',
    color: '#fff',
    backgroundColor: '#d8d8d8',
  },
  coachCardName: {
    marginTop: '.75rem',
    color: theme.palette.primary.main,
  },
  coachCardSubtitle: {
    color: theme.palette.secondary.dark,
  },
  icon: {
    color: theme.palette.primary.dark,
  },
  list: {
    marginBottom: '.75rem',
    '& li': {
      lineHeight: '.75rem',
    },
  },
  seeMoreToggle: {
    textDecoration: 'underline',
    marginBottom: '1rem',
  },
  avatarInitials: {
    fontSize: '2.5rem',
  },
  description: ({ isReservationBooking }) => ({
    '& p': {
      margin: '0',
      fontWeight: '600',
      [theme.breakpoints.up('sm')]: {
        marginBottom: isReservationBooking ? theme.spacing(2) : '0',
      },
    },
  }),
  cancelPolicyText: {
    fontWeight: 600,
    margin: '.75rem 0 .5rem',
  },
}))

export const configureDateTime = (
  startDateTime,
  durationInMinutes,
  timezone,
) => {
  const fullDateTime = toFullTextDayDateFromVisibleTimezone(
    startDateTime,
    timezone,
  )

  const dateTimeForDuration = toFullTextDayDateWithTimezone(
    startDateTime,
    timezone,
  )
  const visualDuration =
    getLessonDurationTextWithTimezone(
      startDateTime,
      durationInMinutes,
      timezone,
    ) || ''

  if (!durationInMinutes) return fullDateTime
  return `${dateTimeForDuration} ${visualDuration}`
}

const PriceItem = ({ priceInCents, classes }) => {
  const theme = useTheme()

  return (
    <ListItem disableGutters={true}>
      <ListItemIcon>
        <SavingsIcon size={24} color={theme.palette.secondary.dark} />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.attributes }}
        primary={formatCurrency(priceInCents / 100)}
      />
    </ListItem>
  )
}

const CancelPolicyItem = ({ classes }) => {
  return (
    <Typography
      variant="body1"
      className={classes.cancelPolicyText}
      gutterBottom
    >
      Cancellation Policy: The cancellation policy requires a notice of at least
      24 hours before the scheduled appointment. If a reservation is made within
      the 24-hour timeframe, the responsibility for any cancellation fees is
      incurred at the time of booking.
    </Typography>
  )
}

const MobileHeader = ({
  facility,
  classes,
  category,
  visualDateTime,
  priceInCents,
  spaceName = '',
  isReservationConfirmation = false,
}) => {
  const [showSeeMore, setShowSeeMore] = useState(false)
  const { suggestedName, description = '' } = category
  const locationInfo = spaceName
    ? `${facility.name} ${spaceName}`
    : facility.name

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={1}>
          <Typography variant={'h3'} className={classes.title}>
            {suggestedName}
          </Typography>
          <Grid item>
            {!showSeeMore && (
              <List dense={true}>
                <Typography
                  variant="subtitle1"
                  onClick={() => setShowSeeMore(true)}
                  className={classes.seeMoreToggle}
                >
                  Read more
                </Typography>
              </List>
            )}
            {showSeeMore && (
              <>
                {description && (
                  <Typography variant="body1" className={classes.description}>
                    <ReactMarkdown>{description}</ReactMarkdown>
                  </Typography>
                )}
                <List dense={true}>
                  <ListItem disableGutters={true}>
                    <ListItemIcon>
                      <GolfCourseIcon className={classes.icons} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.attributes }}
                      primary={locationInfo}
                    />
                  </ListItem>
                  {facility.city && facility.state && (
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <PlaceOutlinedIcon className={classes.icons} />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.attributes }}
                        primary={`${facility.city}, ${facility.state}`}
                      />
                    </ListItem>
                  )}
                  {visualDateTime && (
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <CalendarTodayOutlinedIcon className={classes.icons} />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.attributes }}
                        primary={`${visualDateTime}`}
                      />
                    </ListItem>
                  )}
                  {priceInCents && (
                    <PriceItem priceInCents={priceInCents} classes={classes} />
                  )}
                </List>
                {isReservationConfirmation && (
                  <CancelPolicyItem classes={classes} />
                )}
                <Typography
                  variant="subtitle1"
                  onClick={() => setShowSeeMore(false)}
                  className={classes.seeMoreToggle}
                >
                  Read less
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const FullHeader = ({
  facility,
  classes,
  category,
  visualDateTime,
  priceInCents,
  spaceName = '',
  isReservationConfirmation = false,
}) => {
  const { suggestedName, description = '' } = category
  const locationInfo = spaceName
    ? `${facility.name} ${spaceName}`
    : facility.name

  return (
    <Grid item xs={9}>
      <Grid container direction="column" spacing={1}>
        <Typography variant="h3" className={classes.title}>
          {suggestedName}
        </Typography>
        {description && (
          <ReactMarkdown
            components={{
              p(props) {
                const { node, ...rest } = props
                return (
                  <Typography
                    variant="body1"
                    className={classes.description}
                    {...rest}
                  />
                )
              },
            }}
          >
            {description}
          </ReactMarkdown>
        )}
      </Grid>
      <List dense={true} className={classes.list}>
        <ListItem disableGutters={true}>
          <ListItemIcon>
            <GolfCourseIcon className={classes.icons} />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.attributes }}
            primary={locationInfo}
          />
        </ListItem>
        {facility.city && facility.state && (
          <ListItem disableGutters={true}>
            <ListItemIcon>
              <PlaceOutlinedIcon className={classes.icons} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.attributes }}
              primary={`${facility.city}, ${facility.state}`}
            />
          </ListItem>
        )}
        {visualDateTime && (
          <ListItem disableGutters={true}>
            <ListItemIcon>
              <CalendarTodayOutlinedIcon className={classes.icons} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.attributes }}
              primary={`${visualDateTime}`}
            />
          </ListItem>
        )}
        {priceInCents && (
          <PriceItem priceInCents={priceInCents} classes={classes} />
        )}
      </List>
      {isReservationConfirmation && <CancelPolicyItem classes={classes} />}
    </Grid>
  )
}

FullHeader.propTypes = {
  category: PropTypes.shape({
    suggestedName: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  facility: PropTypes.shape({
    name: PropTypes.string.isRequired,
    city: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  startDateTime: PropTypes.string,
  durationInMinutes: PropTypes.number,
}

const PrivateBookingHeader = ({
  practiceSession,
  facility,
  category,
  spaceName,
}) => {
  // If we have the space name available to the component tree then we are
  // nearly certain that we are referring to Bay Rental reservations
  const location = useLocation()
  const isReservationBooking = location.pathname.includes('reservation')
  const isReservationConfirmation = isReservationBooking && !!spaceName

  const classes = useStyles({ isReservationBooking })
  const isMobile = useIsMobile()
  // const { facilities, practiceSessionTypes = [] } = academy
  const durationInMinutes =
    category?.durationInMinutes || practiceSession.durationInMinutes
  const bookingCategory = {
    suggestedName: practiceSession.title,
    description: practiceSession.description,
  }
  let priceInCents = practiceSession.priceInCents
  const { startDateTime } = qs.parse(location.search)
  const visualDateTime = startDateTime
    ? configureDateTime(startDateTime, durationInMinutes, facility.timezone)
    : null

  if (isReservationConfirmation) {
    priceInCents += estimateCoachingCenterSalesTaxInCents(priceInCents)
  }

  return (
    <Container className={classes.container}>
      <Grid
        container
        className={classes.root}
        spacing={2}
        alignItems="stretch"
        direction="row"
      >
        {isMobile ? (
          <MobileHeader
            facility={facility}
            classes={classes}
            category={category || bookingCategory}
            visualDateTime={visualDateTime}
            priceInCents={priceInCents}
            spaceName={spaceName}
            isReservationConfirmation={isReservationConfirmation}
          />
        ) : (
          <FullHeader
            facility={facility}
            classes={classes}
            category={category || bookingCategory}
            visualDateTime={visualDateTime}
            priceInCents={priceInCents}
            spaceName={spaceName}
            isReservationConfirmation={isReservationConfirmation}
          />
        )}
      </Grid>
    </Container>
  )
}

PrivateBookingHeader.propTypes = {
  practiceSession: PropTypes.shape().isRequired,
  facility: PropTypes.shape().isRequired,
}
export default PrivateBookingHeader
