import React from 'react'
import { connectPagination } from 'react-instantsearch-dom'
import MuiPagination from '@material-ui/lab/Pagination'

function Pagination({ currentRefinement, nbPages, refine, ...props }) {
  if (nbPages == null || nbPages <= 1) {
    return null
  }

  const onPageChange = (_, value) => {
    refine(value)
    window.scrollTo(0, 0)
  }

  const { size, ...rest } = props

  return (
    <MuiPagination
      count={nbPages}
      page={currentRefinement}
      variant="outlined"
      shape="rounded"
      color="primary"
      size={size || 'large'}
      {...rest}
      onChange={onPageChange}
    />
  )
}

export const AlgoliaPagination = connectPagination(Pagination)
