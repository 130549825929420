import { Route, Switch } from 'react-router-dom'

import ActivitiesPage from 'pages/pga-coach/activity-plans/stage/activities'
import SingleActivitiesPage from 'pages/pga-coach/activity-plans/stage/activities/single'
import ActivityPlansPage from 'pages/pga-coach/activity-plans/stage/activity-plans'
import SinglePlansPage from 'pages/pga-coach/activity-plans/stage/activity-plans/SingleActivityPlan'
import CustomPlansPage from 'pages/pga-coach/activity-plans/stage/custom-plans'

const ActivityPlansStageRoutes = () => {
  return (
    <Switch>
      <Route
        path={[
          '/pga-coach/training-resources/activity-plans/:stage/activities',
          '/activity-plans/:stage/activities',
        ]}
        exact
        component={ActivitiesPage}
      />
      <Route
        path={[
          '/pga-coach/training-resources/activity-plans/:stage/plans',
          '/activity-plans/:stage/plans',
        ]}
        exact
        component={ActivityPlansPage}
      />
      <Route
        path={[
          '/pga-coach/training-resources/activity-plans/:stage/custom-plans',
          '/activity-plans/:stage/custom-plans',
        ]}
        exact
        component={CustomPlansPage}
      />
      <Route
        path={[
          '/pga-coach/training-resources/activity-plans/:stage/activities/:id',
          '/activity-plans/:stage/activities/:id',
        ]}
        exact
        component={SingleActivitiesPage}
      />
      <Route
        path={[
          '/pga-coach/training-resources/activity-plans/:stage/plans/:id',
          '/activity-plans/:stage/plans/:id',
        ]}
        exact
        component={SinglePlansPage}
      />
    </Switch>
  )
}

export default ActivityPlansStageRoutes
