import ActivityPlans from 'components/activity-plans'
import { PageHeader } from 'components/page-header'
import { gtmEvent } from 'lib/gtm'
import React, { useEffect } from 'react'

const ActivityPlansPage = () => {
  useEffect(() => {
    gtmEvent({
      event: 'activities-click',
      activitiesCategory: 'coach-activities',
      activitiesAction: 'stages-loaded',
      activitiesLabel: 'stages-loaded',
    })
  }, [])

  const crumbs = [
    {
      label: 'Training & Resources',
      to: '/pga-coach/training-resources',
    },
  ]

  return (
    <>
      <PageHeader title="Activity Plans" crumbs={crumbs} />
      <ActivityPlans />
    </>
  )
}

export default ActivityPlansPage
