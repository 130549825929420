import React, { useEffect, useState } from 'react'
import useStyles from './styles'
import PageHeader from 'pages/pga-coach/students/student-show-page/header-components/page-header'
import { Hidden } from '@material-ui/core'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import CoachInsightsReportCard from 'components/coach-insights-report-card'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GetReportsQuery } from './query'
import { toFullDateShortMonth } from 'utils/dateUtils'
import Skeleton from '@mui/material/Skeleton'
import BusinessInsightsModal from './business-insights-modal'
import Button from '@material-ui/core/Button'

const Reports = () => {
  const classes = useStyles()
  const history = useHistory()
  const { loading: reportsLoading, data: reportsData } = useQuery(
    GetReportsQuery,
  )
  const [openModal, setOpenModal] = useState(false)
  const [sortedReports, setSortedReports] = useState([])

  useEffect(() => {
    if (
      !reportsLoading &&
      (!reportsData?.reports || reportsData.reports.length < 1)
    ) {
      setOpenModal(true)
    }
    if (reportsData?.reports) {
      const sortedArray = [...reportsData.reports].sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
      )
      setSortedReports(sortedArray)
    }
  }, [reportsLoading, reportsData])

  const handleGetStarted = () => {
    setOpenModal(false)
    history.push('/pga-coach/reports/create-report')
  }

  return (
    <div>
      <PageHeader
        title="Performance Reports"
        actions={
          <div style={{ marginRight: '0' }}>
            <Hidden smDown>
              <Button
                variant="contained"
                color="primary"
                className={classes.createReportButton}
                onClick={() => {
                  history.push('/pga-coach/reports/create-report')
                }}
              >
                CREATE REPORT
              </Button>
            </Hidden>
          </div>
        }
      />
      <div className={classes.gridContainer}>
        {reportsLoading ? (
          <>
            <h3>Your Reports</h3>
            <Skeleton variant="rect" width="100%" height={94} />
          </>
        ) : (
          <>
            {sortedReports.length > 0 && <h3>Your Reports</h3>}
            {sortedReports?.map(report => (
              <CoachInsightsReportCard
                key={report.id}
                title={report.name}
                description={`
                  ${report.year}
                  Last updated ${toFullDateShortMonth(
                    report.updatedAt,
                  )}, ${new Date(report.updatedAt).getFullYear()}
                `}
                icon={<BarChartOutlinedIcon />}
                onClick={() => {
                  history.push(
                    `/pga-coach/reports/business-insights/${report.id}`,
                  )
                }}
              />
            ))}
          </>
        )}

        <h3>Sample Reports</h3>
        <CoachInsightsReportCard
          title="Dr. Alison Curdt - Sample report"
          description="2024 Full-time Teaching Professional"
          icon={<BarChartOutlinedIcon />}
          sampleReport
          onClick={() => {
            history.push(
              '/pga-coach/reports/business-insights/sample/alison-curdt-sample',
            )
          }}
        />
      </div>

      <Hidden mdUp>
        <div className={classes.mobileFooter}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push('/pga-coach/reports/create-report')
            }}
          >
            CREATE REPORT
          </Button>
        </div>
      </Hidden>

      <BusinessInsightsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onGetStarted={handleGetStarted}
        classes={classes}
      />
    </div>
  )
}

export default Reports
