import CoachAppBar from 'components/coach-app-bar'
import { ContainerRoot } from 'pages/schedule/components/styles'
import ProgressIndicator from 'components/progress-indicators/progress-indicator'
import BackButton from 'components/back-button'
import { ThemeProvider, Divider, Grid } from '@material-ui/core'
import StepDescriptionOfferings from 'pages/schedule/step-description-offerings'
import PrivateBookingHeader from '../components/private-booking-headerV2'
import themes, { extendTheme } from 'themes'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { getPracticeSessionContent } from './index'

const ReservationsHeaderWrapper = ({
  academy,
  selectedPracticeSession,
  currentStep = 1,
  totalSteps = 1,
  children,
  previousUrl = null,
}) => {
  const progress = (currentStep / totalSteps) * 100
  const facility = academy.facilities[0]
  const practiceSession =
    selectedPracticeSession ?? academy.practiceSessionTypes[0] ?? []
  const category = {
    suggestedName: practiceSession?.title || 'Bay Reservation',
    description: practiceSession?.description || '',
  }

  const { membershipVerification } = getPracticeSessionContent(
    practiceSession?.id,
  )

  const reservationSteps = [
    {
      title: 'Choose a Day and Time',
      description:
        'Find a day and time that works best for your schedule below.',
    },
    {
      title: 'Confirm Member Number',
      description: membershipVerification?.content,
    },
    {
      title: 'Reservation Information',
      description:
        'Please review your reservation details and complete payment below.',
    },
    {
      title:
        'Assumption of Risk, Waiver, and Release of Liability, and Indemnity Agreement',
      description: '',
    },
  ]

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.offeringsRefresh,
      })}
    >
      <Helmet>
        <title>PGA Coach | Bay Reservations</title>
        <meta name="description" content="PGA Coach | Bay Reservations" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <CoachAppBar position="relative" hideContactButton />
      <ContainerRoot
        style={{
          marginBottom: '4rem',
          paddingTop: currentStep > 1 ? '0' : '2rem',
        }}
      >
        <ProgressIndicator value={progress} />
        {currentStep > 1 && <BackButton previousUrl={previousUrl} />}
        <PrivateBookingHeader
          facility={facility}
          practiceSession={practiceSession}
          category={category}
        />
        <Divider variant="fullWidth" style={{ marginBottom: '2.5rem' }} />
        {totalSteps > 1 && (
          <StepDescriptionOfferings
            stepText={`Step ${currentStep} of ${totalSteps}`}
            title={reservationSteps[currentStep - 1].title}
            description={reservationSteps[currentStep - 1].description}
          />
        )}
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </ContainerRoot>
    </ThemeProvider>
  )
}
export default ReservationsHeaderWrapper
ReservationsHeaderWrapper.propTypes = {
  academy: PropTypes.object.isRequired,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  children: PropTypes.node.isRequired,
}
