export const transformMonthlyFinancials = monthlyFinancials => {
  return Object.values(monthlyFinancials).map(item => ({
    month: item.month,
    revenue: item.revenueInCents / 100,
  }))
}

export const transformYtdRevenue = monthlyFinancials => {
  return Object.values(monthlyFinancials).map(monthData => ({
    month: monthData.month,
    revenue: monthData.revenueInCents / 100,
    expenses:
      (monthData.rentPaidToClubInCents +
        monthData.purchasesFromClubInCents +
        monthData.otherExpensesInCents +
        monthData.marketingSpendInCents) /
      100,
  }))
}

export const transformYtdExpenses = monthlyFinancials => {
  return Object.values(monthlyFinancials).map(monthData => ({
    month: monthData.month,
    purchasesFromClub: monthData.purchasesFromClubInCents / 100,
    rentPaidToClub: monthData.rentPaidToClubInCents / 100,
    otherExpenses: monthData.otherExpensesInCents / 100,
    marketingSpend: monthData.marketingSpendInCents / 100,
  }))
}

export const calculateTotalExpenses = monthlyFinancials => {
  return Object.values(monthlyFinancials).reduce(
    (acc, monthData) =>
      acc +
      monthData.otherExpensesInCents +
      monthData.purchasesFromClubInCents +
      monthData.rentPaidToClubInCents +
      monthData.marketingSpendInCents,
    0,
  )
}
