import React from 'react'
import { Popper } from '@material-ui/core'

export default function HitsPopper({ style: { width, ...style }, ...props }) {
  return (
    <Popper
      style={{ ...style, width: width === 0 ? 0 : '90%', zIndex: 1300 }}
      {...props}
      placement="bottom-start"
      modifiers={{
        flip: {
          enabled: false,
        },
      }}
    />
  )
}
