import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useStyles } from './styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import RouterLinkButton from 'components/router-link-button'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import differenceInDays from 'date-fns/differenceInDays'
import pluralize from 'lib/pluralize'
import RedeemIcon from '@material-ui/icons/Redeem'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'

const LessonPackCard = ({
  expiresAt,
  totalCredits,
  remainingCredits,
  lessonCredits,
  lessonType,
  isUsedOrExpired,
  id: lessonPackId,
  onGiftClick,
}) => {
  const { title, coach, locations, id, giftingEnabled } = lessonType
  const locationId = locations[0].id
  const { name, bookingUrl } = coach
  const relativeBookingUrl =
    '/coach' +
    bookingUrl.split('/coach')[1] +
    `?lessonTypeId=${id}&locationId=${locationId}`
  const buyMoreVouchersUrl = `${relativeBookingUrl}&isLessonPacks=true`
  const bookSessionUrl = `${relativeBookingUrl}&lessonCreditBalanceQuantity=${remainingCredits}`

  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenuClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = url => {
    history.push(url)
    handleMenuClose()
  }

  const handleGiftClick = () => {
    onGiftClick(lessonPackId)
    handleMenuClose()
  }

  const daysToExpire = differenceInDays(parseISO(expiresAt), new Date())
  const showExpireWarning = daysToExpire < 30

  const classes = useStyles()

  const formattedExpirationDate = expDate => {
    return format(parseISO(expDate), 'MMMM d, yyyy')
  }

  const formattedLastUpdatedAtDate = lastUpdAt => {
    if (!lastUpdAt) return null
    return format(lastUpdAt, 'MMMM d, yyyy')
  }

  const defineLastUpdatedDate = credits => {
    return Math.max(...credits.map(item => new Date(item.updatedAt)))
  }

  let lastUpdatedAt
  if (isUsedOrExpired) {
    lastUpdatedAt = formattedLastUpdatedAtDate(
      defineLastUpdatedDate(lessonCredits),
    )
  }

  return (
    <Box className={classes.bookingType}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" mb={1} style={{ width: '100%' }}>
          <Avatar className={classes.iconStyled}>
            <ConfirmationNumberOutlinedIcon
              color={isUsedOrExpired ? 'disabled' : 'primary'}
            />
          </Avatar>
          {!isUsedOrExpired && (
            <Box display="flex" style={{ marginLeft: 'auto' }}>
              {showExpireWarning && (
                <Chip
                  className={clsx(classes.chip, classes.chipWarning)}
                  label="Expires Soon"
                />
              )}
              {giftingEnabled && totalCredits === remainingCredits && (
                <Chip
                  className={clsx(
                    classes.chipGiftable,
                    classes.chipGiftableText,
                  )}
                  icon={<RedeemIcon className={classes.chipGiftableIcon} />}
                  label="Giftable"
                />
              )}
              <Chip
                className={clsx(classes.chip, classes.chipInfo)}
                label={`${remainingCredits} ${pluralize(
                  remainingCredits,
                  'lesson',
                )} left`}
              />
              {giftingEnabled && remainingCredits === totalCredits && (
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  size="small"
                  className={classes.moreMenu}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
          )}
          {!remainingCredits && (
            <Box display="flex" style={{ marginLeft: 'auto' }}>
              <Chip
                className={clsx(classes.chip, classes.chipInfo)}
                label="Used"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Typography variant="body1" gutterBottom>
        {title}
      </Typography>
      <Box mb={2}>
        <Typography
          variant="caption"
          className={`${classes.captionText} ${classes.lighterText}`}
        >
          {name}
        </Typography>
        <br />
        {isUsedOrExpired && (
          <>
            <Typography
              variant="caption"
              className={`${classes.captionText} ${classes.lighterText}`}
            >
              {totalCredits} {pluralize(totalCredits, 'lesson')}
            </Typography>
            <br />
            <Typography
              variant="caption"
              className={`${classes.captionText} ${classes.lighterText}`}
            >
              Last used: {lastUpdatedAt}
            </Typography>
            <br />
          </>
        )}
        {expiresAt && (
          <Typography
            variant="caption"
            className={`${classes.captionText} ${classes.lighterText}`}
          >
            Expires: {formattedExpirationDate(expiresAt)}
          </Typography>
        )}
      </Box>
      {!isUsedOrExpired && (
        <Grid container spacing={1} className={classes.actionContainer}>
          <Grid item xs={12} md={6}>
            {giftingEnabled && remainingCredits === totalCredits ? (
              <Button
                onClick={() => onGiftClick(lessonPackId)}
                variant="outlined"
                color="primary"
                className={classes.actionButton}
                startIcon={<RedeemIcon />}
              >
                Gift Lessons
              </Button>
            ) : (
              <RouterLinkButton
                to={buyMoreVouchersUrl}
                variant="outlined"
                color="primary"
                className={classes.actionButton}
              >
                Buy Another Pack
              </RouterLinkButton>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <RouterLinkButton
              to={bookSessionUrl}
              variant="contained"
              color="primary"
              className={classes.actionButton}
              startIcon={<CalendarTodayOutlinedIcon />}
            >
              Book a Session
            </RouterLinkButton>
          </Grid>
          {giftingEnabled && remainingCredits === totalCredits && (
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick(bookSessionUrl)}>
                <ListItemIcon>
                  <CalendarTodayOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Book Session</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick(buyMoreVouchersUrl)}>
                <ListItemIcon>
                  <ConfirmationNumberOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Buy Another Pack</ListItemText>
              </MenuItem>
              {giftingEnabled && remainingCredits === totalCredits && (
                <MenuItem onClick={handleGiftClick}>
                  <ListItemIcon>
                    <RedeemIcon />
                  </ListItemIcon>
                  <ListItemText>Gift Lesson Pack</ListItemText>
                </MenuItem>
              )}
            </Menu>
          )}
        </Grid>
      )}
    </Box>
  )
}

export default LessonPackCard
