import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: '16px',
    maxWidth: '1090px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
      padding: '0',
    },
  },
  exportButton: {
    marginRight: '0',
    borderColor: '#00234B80',
    color: '#043362',
  },
  yearSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
  select: {
    width: '10rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0',
    },
  },
  gridMobileLabel: {
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '32px',
    color: '#000000',
    margin: 0,
  },
  infoTextTitle: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#000000',
    marginBottom: '8px',
  },
}))

export default useStyles
