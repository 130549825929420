import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import useStyles from './styles'
import { connectHits } from 'react-instantsearch-dom'
import { titleToURL } from '../../lib/transformStage'
import { gtmEvent } from 'lib/gtm'

const countActivities = hits => {
  hits.map(hit => {
    const sections = hit.sections
    let activitiesCount = 0
    sections.map(section => {
      activitiesCount += section.activities.length
      return activitiesCount
    })
    hit.activitiesCount = activitiesCount
    return hit
  })
}

const cardPath = (hit, path) => {
  const prefix = path.startsWith('/pga-coach')
    ? '/pga-coach/training-resources/activity-plans/'
    : '/activity-plans/'

  return `${prefix}${titleToURL(hit.stage).name}/plans/${hit.objectID}`
}

const Hits = ({ hits }) => {
  const styles = useStyles()
  const { path } = useRouteMatch()
  countActivities(hits)

  const handleClick = name => {
    gtmEvent({
      event: 'activities-click',
      activitiesCategory: 'coach-activities',
      activitiesAction: 'featured-plan-click',
      activitiesLabel: name,
    })
  }

  return (
    <Grid container spacing={2}>
      {hits.map(hit => (
        <Box className={styles.mainWrapper} key={hit.objectID}>
          <Grid item container lg={8} md={8} xs={7}>
            <Grid item lg={6} md={8} xs={12}>
              <Typography variant="h6" className={styles.heading}>
                {hit.title}
              </Typography>
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <Typography variant="subtitle1" className={styles.subtitle}>
                {hit.totalDurationMins} Minutes
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={2} md={3} xs={3}>
            <Chip
              label={`${hit.activitiesCount} Activities`}
              className={styles.chip}
            />
          </Grid>
          <Grid item sm={2} xs={3}>
            <Button
              className={styles.button}
              color="primary"
              variant="outlined"
              component={Link}
              onClick={() => handleClick(hit.title)}
              to={cardPath(hit, path)}
            >
              VIEW
            </Button>
          </Grid>
        </Box>
      ))}
    </Grid>
  )
}

const ActivityPlanCards = connectHits(Hits)

export default ActivityPlanCards
