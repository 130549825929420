import React, { useState, useEffect, useContext } from 'react'
import ExternalLinkButton from 'components/external-link-button'
import { LIGHTSPEED_VT_URL } from 'utils/constants'
import LinearProgress from '@material-ui/core/LinearProgress'
import { get } from 'lodash'
import { useHistory, Link } from 'react-router-dom'
import Disclaimer from 'components/disclaimer'
import { parseEvents } from 'utils/eventUtils'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import {
  ADMTrainingExternalLink,
  GROUP_EVENT_PAGE_LIMIT,
} from 'lib/pga-dot-coach'
import { red } from '@material-ui/core/colors'
import useIsMobile from 'lib/hooks/use-is-mobile'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import EventsDisplaySelection from './events-display-selection'
import { RefetchContext } from 'lib/utils/refetchContext'

const EventsPageData = ({
  eventsData,
  selectedTab,
  setSelectedTab,
  showCanceledEvents,
  offset,
  setOffset,
  setShowInProgressTab,
}) => {
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [lastPage, setLastPage] = useState(false)

  const history = useHistory()
  const isMobile = useIsMobile()
  const refetch = useContext(RefetchContext)

  useEffect(() => {
    passEventsToState(eventsData, selectedTab)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData, showCanceledEvents, selectedTab])

  const goToTrainingLink = LIGHTSPEED_VT_URL
  const currentPage = offset / GROUP_EVENT_PAGE_LIMIT + 1

  const hideInProgressTabIfNoEvents = (selectedTab, events) => {
    if (selectedTab === 'inProgress' && events.length === 0) {
      setSelectedTab('upcoming')
      setShowInProgressTab(false)
    }
  }

  const passEventsToState = (eventsData, selectedTab) => {
    if (eventsData) {
      const inputEvents = get(eventsData, 'currentUser.groupEvents', [])
      const newEvents = parseEvents(inputEvents, selectedTab)
      setEvents(newEvents)
      hideInProgressTabIfNoEvents(selectedTab, newEvents)
      if (inputEvents.length < GROUP_EVENT_PAGE_LIMIT && offset > 0) {
        setLastPage(true)
      }
    }
  }

  const retreatPagination = async () => {
    const newOffset =
      offset - GROUP_EVENT_PAGE_LIMIT < 0 ? 0 : offset - GROUP_EVENT_PAGE_LIMIT
    setOffset(newOffset)
    await refetch({ offset: newOffset })
    setLastPage(false)
  }
  const advancePagination = async () => {
    const newOffset = offset + GROUP_EVENT_PAGE_LIMIT
    setOffset(newOffset)
    await refetch({ offset: newOffset })
  }

  const { canPromoteContent } = eventsData.currentUser.attributes
  const totalEvents = get(eventsData, 'currentUser.groupEvents', []).length

  if (isLoading) return <LinearProgress color="secondary" />
  return (
    <>
      {!canPromoteContent ? (
        <Container maxWidth="md">
          <Box mt={4} p={2} borderRadius={4} bgcolor={red[50]}>
            <Typography variant="body2" align="center">
              {
                'You are currently ineligible to promote your events on PGA.com. Please complete '
              }
              <ADMTrainingExternalLink />
              {' to become eligible.'}
            </Typography>
          </Box>
        </Container>
      ) : (
        <Container maxWidth="md">
          <Box mt={3}>
            <Disclaimer
              icon={<SchoolOutlinedIcon fontSize="large" />}
              description={
                <>
                  <strong>
                    {'Need help getting started with group coaching? '}
                  </strong>
                  {'Review our course on Building Coaching Programs'}
                </>
              }
              action={
                <ExternalLinkButton
                  variant={isMobile ? 'text' : 'outlined'}
                  color="primary"
                  component={Link}
                  href={goToTrainingLink}
                >
                  View Training
                </ExternalLinkButton>
              }
            />
          </Box>
        </Container>
      )}
      <EventsDisplaySelection
        events={events}
        totalEvents={totalEvents}
        history={history}
        selectedTab={selectedTab}
        advancePagination={advancePagination}
        retreatPagination={retreatPagination}
        currentPage={currentPage}
        lastPage={lastPage}
      />
    </>
  )
}
export default EventsPageData
