import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useStyles from './settings-box.styles'
import CheckIcon from '@material-ui/icons/Check'

const SettingsBox = ({
  icon,
  title,
  subtitle,
  action,
  isCompleted,
  cta,
  children,
}) => {
  const styles = useStyles()

  return (
    <Box className={styles.mainWrapper}>
      <Box display="flex" alignItems="center">
        {!isCompleted ? (
          icon
        ) : (
          <div className={styles.icon}>
            <CheckIcon />
          </div>
        )}
        <Box className={styles.settingsDescription}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="body2">
            <ReactMarkdown>{subtitle}</ReactMarkdown>
          </Typography>
          {cta}
        </Box>
        {action}
      </Box>
      {children}
    </Box>
  )
}

SettingsBox.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: PropTypes.element,
  children: PropTypes.element,
}

export default SettingsBox
