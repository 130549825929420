import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { Box, TextField, InputAdornment } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'
import { ReactComponent as MapMarker } from 'images/event-search/map-marker.svg'
import Group from './search-box/group'
import { QuerySelection } from './selection'
import { types as hitTypes } from './search-box/hit'
import { autocompleteSearch } from './search'
import createContainer from './search-box/create-container'
import CustomHighlight from './search-box/custom-highlight'
import HitsPopper from './search-box/hits-popper'
import SearchHits from './search-box/search-hits'

const promiseDebounce = (fn, time) => {
  const debounced = debounce(
    (resolve, ...args) => fn(...args).then(resolve),
    time,
  )

  const promisfied = (...args) =>
    new Promise(resolve => debounced(resolve, ...args))
  promisfied.cancel = debounced.cancel
  return promisfied
}

const debounceSearch = searchIndex =>
  promiseDebounce(autocompleteSearch(searchIndex), 300)

const groupOrdering = {
  [hitTypes.PLACE]: 0,
  [hitTypes.COACH]: 1,
  [hitTypes.FACILITY]: 2,
}

export default function SearchBox({
  value,
  notClearable,
  noPopupIcon,
  includeAdornment = true,
  onChange,
  onAutocompleteOptions,
  searchIndex,
  buttonText,
}) {
  const [query, setQuery] = useState(value.label)
  const [searching, setSearching] = useState(false)
  const [options, setOptions] = useState([])
  const havePrograms = options.some(({ type }) =>
    [hitTypes.FACILITY, hitTypes.COACH].includes(type),
  )

  const search = useMemo(() => debounceSearch(searchIndex), [searchIndex])

  const sortedOptions = useMemo(
    () => sortBy(options, option => groupOrdering[option.type]),
    [options],
  )

  const viewAllMatching = useCallback(
    () => onChange(new QuerySelection({ query })),
    [onChange, query],
  )

  useEffect(() => {
    if (query === '') {
      search.cancel()
      setSearching(false)
      setOptions([])
      return
    }

    setSearching(true)

    search(query).then(results => {
      setSearching(false)
      setOptions(results.flat())
    })
  }, [query, onAutocompleteOptions, search])

  const adornment = includeAdornment && {
    startAdornment: (
      <InputAdornment position="start">
        <Box color="secondary.main" component="span">
          <MapMarker />
        </Box>
      </InputAdornment>
    ),
  }

  const HitsContainer = useMemo(
    () =>
      createContainer({
        query,
        havePrograms,
        onViewAllMatching: viewAllMatching,
        buttonText,
      }),
    [query, havePrograms, viewAllMatching, buttonText],
  )

  const noOptionsText = (() => {
    if (query === '') return 'Search by location, coach name, or facility name'
    if (searching) return 'Searching'
    return 'No results found'
  })()

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.label || ''
      }
      filterOptions={x => x}
      noOptionsText={noOptionsText}
      options={sortedOptions}
      groupBy={option => option.type}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disableClearable={notClearable}
      clearOnEscape
      clearOnBlur
      value={value}
      popupIcon={noPopupIcon ? null : <KeyboardArrowDownIcon />}
      PaperComponent={HitsContainer}
      PopperComponent={HitsPopper}
      ListboxComponent={SearchHits}
      renderGroup={({ key, group, children }) => (
        <Group key={key} type={group} children={children} />
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            ...adornment,
            placeholder: 'City, State, or Zip',
          }}
        />
      )}
      renderOption={option => option.renderHighlightedContent(CustomHighlight)}
      onChange={(_, newValue) => onChange?.(newValue?.toSelection())}
      onInputChange={(_, newQuery) => setQuery(newQuery)}
      onOpen={() => onAutocompleteOptions({ isOpen: true })}
      onClose={() => onAutocompleteOptions({ isOpen: false })}
    />
  )
}
