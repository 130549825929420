import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useMutation, gql } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ReCAPTCHA from 'react-google-recaptcha'
import { RECAPTCHA_V2_KEY } from 'env'
import { CtrlTextField } from 'components/form-util'
import EmailFieldWithSuggestions from 'components/form-util/email-field-with-suggestions'

const useStyles = makeStyles(theme => ({
  input: {
    background: 'white',
  },
}))

const CREATE_SURVEY_RESPONSE = gql`
  mutation CreateSurveyResponse($surveyName: String!, $data: JSON!) {
    createSurveyResponse(surveyName: $surveyName, data: $data) {
      success
    }
  }
`

const validationSchema = Yup.object().shape({
  zipCode: Yup.string()
    .max(10)
    .required('This field is required'),
  email: Yup.string()
    .email()
    .required('This field is required'),
})

export function SurveyForm({ surveyName, eventsName = 'events' }) {
  const classes = useStyles()
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(null)
  const {
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const [createSurveyResponse] = useMutation(CREATE_SURVEY_RESPONSE, {
    context: { recaptchaToken },
    onCompleted: data => {
      setIsFormSubmitSuccess(data.createSurveyResponse.success)
    },
    onError: () => {
      setIsFormSubmitSuccess(false)
    },
  })

  const onSubmit = values => {
    setIsFormSubmitSuccess(null)
    return createSurveyResponse({
      variables: {
        surveyName,
        data: {
          zipCode: values.zipCode,
          email: values.email,
        },
      },
    })
  }
  if (isFormSubmitSuccess) {
    return (
      <Container maxWidth="sm">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5" align="center">
              You&apos;re on the list!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Thank you so much for your interest. We&apos;ll let you know when{' '}
              {eventsName} are available near you.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    )
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CtrlTextField
            name="zipCode"
            control={control}
            errors={errors}
            label="Zip"
            variant="outlined"
            fullWidth
            inputProps={{
              style: { background: 'white' },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailFieldWithSuggestions
            name="email"
            label="Email"
            variant="outlined"
            control={control}
            errors={errors}
            classes={classes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ReCAPTCHA
            sitekey={RECAPTCHA_V2_KEY}
            onChange={token => setRecaptchaToken(token)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            disabled={isSubmitting}
          >
            Sign up
          </Button>
          {isFormSubmitSuccess === false && (
            <Typography variant="caption" color="error">
              An error has occurred. Please try again later.
            </Typography>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
