import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    marginBottom: 0,
    overflow: 'auto',
    padding: 0,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
    },
  },
}))

const SearchHits = React.forwardRef(({ children, ...props }, ref) => {
  const classes = useStyles()

  return (
    <ul {...props} ref={ref} className={classes.list}>
      {children}
    </ul>
  )
})

export default SearchHits
