import React, { useState } from 'react'
import { DateTime, Interval } from 'luxon'
import { useQuery, gql } from '@apollo/client'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { currency } from 'lib/utils/string'
import { toFullDateAndTime } from 'utils/dateUtils'
import Error from 'components/error'
import RouterLinkButton from 'components/router-link-button'
import {
  getProductName,
  getTransactionType,
} from 'components/transaction-summary/transaction-utils'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import themes from 'themes'

const GET_REGISTRATION_PAYMENTS = gql`
  query GetRegistrationPayments($createdAt: DateTimeRangeInput) {
    registrationPayments(createdAt: $createdAt) {
      id
      createdAt
      totalInCents
      payor {
        ... on Contact {
          firstName
          lastName
        }
        ... on Coach {
          name
        }
      }
      products {
        ... on Enrollment {
          lesson {
            title
          }
        }
        ... on LessonPack {
          lessonType {
            title
          }
          lessonPackOffering {
            quantity
          }
        }
        ... on FamilyCupProgram {
          name
        }
        ... on JRLChampionshipSeasonProgram {
          name
        }
        ... on JRLLocalLeagueProgram {
          name
        }
        ... on JRLProgramEnrollment {
          player {
            fullName
          }
          program {
            name
          }
        }
        ... on JRLKitOrder {
          category
          program {
            name
          }
        }
        ... on FamilyCupProgramEnrollment {
          program {
            name
          }
          captain {
            firstName
            lastName
          }
        }
      }
    }
  }
`

const useTransactionHistoryQuery = (startDate, endDate) => {
  const createdAtInterval =
    startDate && endDate && Interval.fromDateTimes(startDate, endDate)

  const query = useQuery(GET_REGISTRATION_PAYMENTS, {
    variables: {
      createdAt: {
        from: createdAtInterval?.start,
        to: createdAtInterval?.end,
      },
    },
  })

  return { ...query }
}

const TransactionHistoryRow = ({ registrationPayment }) => {
  const { id, payor, totalInCents, createdAt, products } = registrationPayment
  const payorName = payor.name || `${payor.firstName} ${payor.lastName}`
  const initials = payorName
    .split(' ')
    .map(s => s[0])
    .join('')
  const amount = currency(totalInCents / 100)
  const product = getProductName(products[0])
  const transactionType = getTransactionType(products[0])

  return (
    <Box display="flex" justifyContent="space-between" py={2}>
      <Box display="inline-flex" mx={2}>
        <Avatar>{initials}</Avatar>
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body1">{payorName}</Typography>
        <Typography variant="body2" color="textSecondary">
          {amount} on {toFullDateAndTime(createdAt)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {transactionType}
        </Typography>
        <Typography variant="body1">{product}</Typography>
      </Box>
      <Box display="inline-flex">
        <RouterLinkButton
          to={`/account/payments/transactions/${id}`}
          color="primary"
        >
          View Details
        </RouterLinkButton>
      </Box>
    </Box>
  )
}

const TransactionHistory = () => {
  const [selectedMonth, setSelectedMonth] = useState(DateTime.now())
  const [searchText, setSearchText] = useState('')

  const { data, previousData, loading, error } = useTransactionHistoryQuery(
    selectedMonth.startOf('month'),
    selectedMonth.endOf('month'),
  )

  const registrationPayments =
    data?.registrationPayments || previousData?.registrationPayments || []

  const filteredPayments = registrationPayments.filter(payment => {
    const payorName =
      payment.payor.name ||
      `${payment.payor.firstName} ${payment.payor.lastName}`
    return payorName.toLowerCase().includes(searchText.toLowerCase())
  })

  const TransactionHistoryTable = () => {
    if (loading) return <LinearProgress color="primary" />
    if (error) return <Error error={error} />
    if (filteredPayments.length === 0) {
      return (
        <Box mb={2}>
          <Divider />
          <Box py={4} px={2}>
            <Typography variant="body1">
              {searchText
                ? 'No transactions found matching your search.'
                : 'No results for the selected date range. Transactions that occur within the selected dates will appear here.'}
            </Typography>
          </Box>
          <Divider />
        </Box>
      )
    }

    return (
      <Box mb={2}>
        <Divider />
        {filteredPayments.map(rp => (
          <Box key={rp.id}>
            <TransactionHistoryRow registrationPayment={rp} />
            <Divider />
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Container>
      <Box my={2}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Search by name"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ThemeProvider theme={createTheme(themes.pgaCoach)}>
              <LocalizationProvider dateAdapter={CustomLuxonAdapter}>
                <DatePicker
                  label="Select Month"
                  value={selectedMonth}
                  onChange={newValue => setSelectedMonth(newValue)}
                  views={['month', 'year']}
                  format="MMMM yyyy"
                />
              </LocalizationProvider>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Box>
      <TransactionHistoryTable />
    </Container>
  )
}

export default TransactionHistory
