import React from 'react'
import { get } from 'lodash'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { PageHeader } from 'components/page-header'
import Error from 'components/error'
import { MY_COACH_PROFILE } from './query'
import RouterLinkButton from 'components/router-link-button'
import ExternalLinkButton from 'components/external-link-button'
import { PGA_COM_HOST } from 'env'
import SocialShareDialog from 'components/social-share-dialog'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { useIsMobile, useIsTablet } from 'lib/hooks'
import { IconShare } from 'components/atoms/icons'
import IconButton from '@material-ui/core/IconButton'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CoachProfileView from './coach-profile-view'
import CoachReviewsView from './coach-reviews-view'

const Actions = ({ customSlug }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  if (isTablet || isMobile) {
    return (
      <Box display="flex" style={{ marginRight: '-8px' }}>
        <IconButton
          color="primary"
          href={`${PGA_COM_HOST}/coach/${customSlug}`}
        >
          <VisibilityOutlinedIcon />
        </IconButton>

        <SocialShareDialog
          trigger={
            <IconButton color="primary">
              <IconShare />
            </IconButton>
          }
          shareUrl={`${PGA_COM_HOST}/coach/${customSlug}`}
          shareTitle="Share Your Coach Profile"
          dialogTitle="Share Your Coach Profile"
          att="coach-profile-link"
        />

        <IconButton
          color="primary"
          href="profile/edit"
          data-cy="edit-profile-link"
        >
          <EditOutlinedIcon />
        </IconButton>
      </Box>
    )
  }
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ width: '100%', margin: 0 }}
      >
        <ExternalLinkButton
          variant="outlined"
          color="primary"
          startIcon={<VisibilityOutlinedIcon />}
          href={`${PGA_COM_HOST}/coach/${customSlug}`}
        >
          View
        </ExternalLinkButton>
        <Box>
          <SocialShareDialog
            trigger={
              <Button
                variant="outlined"
                color="primary"
                startIcon={<IconShare />}
                style={{ marginLeft: '8px' }}
              >
                Share
              </Button>
            }
            shareUrl={`${PGA_COM_HOST}/coach/${customSlug}`}
            shareTitle="Share Your Coach Profile"
            dialogTitle="Share Your Coach Profile"
            att="coach-profile-link"
            showProfileUrl
          />
          <Button
            variant="outlined"
            color="primary"
            to="profile/edit"
            startIcon={<EditOutlinedIcon />}
            component={RouterLinkButton}
            style={{ marginLeft: '8px' }}
            data-cy="edit-profile-link"
          >
            Edit
          </Button>
        </Box>
      </Box>
    </>
  )
}

const CoachProfileIndexPage = () => {
  const { path, isExact } = useRouteMatch()

  const { data, loading, error, refetch } = useQuery(MY_COACH_PROFILE)

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} />

  const coachProfile = get(data, 'currentUser.attributes.coachProfile')
  const facilities = get(data, 'currentUser.attributes.facilities')

  const customSlug = coachProfile?.customSlug // customSlug is only available for coaches who can promote their profile

  const tabs = (
    <Tabs value={isExact ? 'coach profile' : 'reviews'}>
      <Tab
        label="Coach Profile"
        value="coach profile"
        component={Link}
        to={path}
      />
      <Tab
        label="Reviews"
        value="reviews"
        component={Link}
        to={`${path}/reviews`}
      />
    </Tabs>
  )
  return (
    <>
      <PageHeader
        title="Coach Profile"
        tabs={tabs}
        actions={coachProfile && <Actions customSlug={customSlug} />}
      />
      <Switch>
        <Route
          exact
          path={`${path}`}
          component={() => (
            <CoachProfileView
              coachProfile={coachProfile}
              facilities={facilities}
              refetch={refetch}
            />
          )}
        />
        <Route
          exact
          path={`${path}/reviews`}
          component={() => <CoachReviewsView />}
        />
      </Switch>
    </>
  )
}

export default CoachProfileIndexPage
