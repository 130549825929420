import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { PageHeader } from 'components/page-header'
import RouterLinkButton from 'components/router-link-button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Switch from '@material-ui/core/Switch'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import EventsPageData from './event-components/events-show-page'
import { GET_PAST_GROUP_EVENTS, GET_UPCOMING_GROUP_EVENTS } from './query'
import { GROUP_EVENT_PAGE_LIMIT } from 'lib/pga-dot-coach'
import { useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import { RefetchContext } from 'lib/utils/refetchContext'

const useStyles = makeStyles(theme => ({
  tabWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    paddingBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  showCancellations: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  containerStyles: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      marginBottom: '60px',
    },
  },
  base: {
    background: '#e9f5fe',
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: '0 16px',
    background: 'e9f5fe',
    color: 'e9f5fe',
  },
  logo: {
    width: '50px',
    minWidth: '50px',
    alignSelf: 'center',
  },
  body: {
    padding: '16px 16px',
  },
  button: {
    minWidth: '150px',
  },
}))

const EventsPage = () => {
  const [selectedTab, setSelectedTab] = useState('inProgress')
  const [showInProgressTab, setShowInProgressTab] = useState(true)
  const [showCanceledEvents, setShowCanceledEvents] = useState(false)
  const [offset, setOffset] = useState(0)
  const classes = useStyles()
  const { path } = useRouteMatch()

  useEffect(() => {
    setOffset(0)
  }, [selectedTab, showCanceledEvents])

  const showPastEvents = selectedTab === 'past'
  const startDateTime = showPastEvents
    ? {
      from: null,
      to: DateTime.local()
        .startOf('day')
        .toISO(),
    }
    : {
      from: DateTime.now()
        .startOf('day')
        .toISO(),
      to: null,
    }

  const pastEventsVariables = {
    orderBy: { field: 'START_DATE_TIME', direction: 'DESC' },
    startDateTime: startDateTime,
    includeCanceled: showCanceledEvents,
    limit: GROUP_EVENT_PAGE_LIMIT,
    offset: offset,
    skip: selectedTab !== 'past',
  }

  const upcomingEventsVariables = {
    orderBy: { field: 'START_DATE_TIME', direction: 'ASC' },
    startDateTime: startDateTime,
    includeCanceled: showCanceledEvents,
    limit: GROUP_EVENT_PAGE_LIMIT,
    offset: offset,
    skip: selectedTab === 'past',
  }

  const useGroupEventsQuery = (query, variables, skip) => {
    return useQuery(query, {
      variables: variables,
      skip: skip,
    })
  }

  const pastEventsResult = useGroupEventsQuery(
    GET_PAST_GROUP_EVENTS,
    pastEventsVariables,
    selectedTab !== 'past',
  )
  const upcomingEventsResult = useGroupEventsQuery(
    GET_UPCOMING_GROUP_EVENTS,
    upcomingEventsVariables,
    selectedTab === 'past',
  )

  const queryResult =
    selectedTab === 'past' ? pastEventsResult : upcomingEventsResult

  const { data, loading, error, refetch } = queryResult

  const crumbs = [
    {
      label: 'Offerings',
      to: [...path.split('/').slice(0, 3), 'offerings'].join('/'),
    },
  ]
  return (
    <>
      <PageHeader
        title="Events"
        crumbs={crumbs}
        actions={
          <RouterLinkButton
            variant="contained"
            color="primary"
            to="/pga-coach/bookings/events/new"
          >
            Create Event
          </RouterLinkButton>
        }
        tabs={
          <Container className={classes.containerStyles}>
            <Box className={classes.tabWrapper}>
              <Tabs
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
              >
                {showInProgressTab && (
                  <Tab label="In Progress" value="inProgress" />
                )}
                <Tab label="Upcoming" value="upcoming" />
                <Tab label="Past" value="past" />
              </Tabs>
              <Box>
                <FormControlLabel
                  className={classes.showCancellations}
                  size="size"
                  control={
                    <Switch
                      checked={showCanceledEvents}
                      onChange={e => setShowCanceledEvents(e.target.checked)}
                    />
                  }
                  label={
                    <Typography variant="button">Show Cancellations</Typography>
                  }
                />
              </Box>
            </Box>
          </Container>
        }
        fixedMobileActions
      />
      {loading && <LinearProgress color="primary" />}
      {error && <Error />}
      {!loading && !error && (
        <RefetchContext.Provider value={refetch}>
          <EventsPageData
            eventsData={data}
            selectedTab={selectedTab}
            setShowInProgressTab={setShowInProgressTab}
            setSelectedTab={setSelectedTab}
            showCanceledEvents={showCanceledEvents}
            offset={offset}
            setOffset={setOffset}
          />
        </RefetchContext.Provider>
      )}
    </>
  )
}

export default EventsPage
