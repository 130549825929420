import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const groupLabels = {
  place: 'LOCATIONS',
  coach: 'PGA COACHES',
  facility: 'PGA FACILITIES',
}

const useStyles = makeStyles(theme => ({
  group: {
    [theme.breakpoints.up('md')]: {
      '& + &': {
        borderLeft: '1px solid #ccc',
        marginLeft: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
  },

  title: {
    color: 'rgb(124, 124, 124)',
    display: 'none',
    fontSize: 13,
    fontWeight: 700,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },

  list: {
    padding: 0,

    '& li': {
      alignItems: 'flex-start',
    },

    '& li:nth-of-type(1n + 2)': {
      display: 'none',
    },

    [theme.breakpoints.up('md')]: {
      '& li': {
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
      },
    },

    '@media (min-height: 600px)': {
      '& li:nth-of-type(2)': {
        display: 'flex',
      },

      '& li:nth-of-type(1n + 3)': {
        display: 'none',
      },
    },

    [`${theme.breakpoints.down('sm')} and (min-height: 700px)`]: {
      '& li:nth-of-type(3)': {
        display: 'flex',
      },

      '& li:nth-of-type(1n + 4)': {
        display: 'none',
      },
    },

    [`${theme.breakpoints.up('md')} and (min-height: 700px)`]: {
      '& li:nth-of-type(1n + 2)': {
        display: 'flex',
      },
    },
  },
}))

export default function Group({ type, children }) {
  const classes = useStyles()

  return (
    <li className={classes.group}>
      <Typography variant="subtitle2" className={classes.title}>
        {groupLabels[type]}
      </Typography>

      <ul className={classes.list}>{children}</ul>
    </li>
  )
}
