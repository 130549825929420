import React, { useState, useEffect } from 'react'
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined'
import Badge from '@material-ui/core/Badge'
import Shell from 'components/shell/shell'
import logo from 'images/pga-coach-logo.png'
import { withProtectedRoute, roles, useAuth } from 'lib/auth'
import themes, { extendTheme } from 'themes'
import BookingsPage from 'pages/pga-coach/bookings'
import OfferingsPage from 'pages/pga-coach/bookings/offerings'
import StudentsPage from 'pages/pga-coach/students'
import FacilitiesPage from 'pages/pga-coach/facilities'
import FacilitiesPageNew from 'pages/pga-coach/facilities/new'
import FacilitiesPageShow from 'pages/pga-coach/facilities/show'
import FacilitiesPageEdit from 'pages/pga-coach/facilities/edit'
import FacilityOnboardingPage from 'pages/pga-coach/facilities/onboarding'
import Messages from 'pages/pga-coach/messages'
import CoachProfileIndexPage from 'pages/pga-coach/profile/index'
import EditProfilePage from 'pages/pga-coach/profile/edit'
import CoachDashboard from 'pages/pga-coach/dashboard'
import CoachSettingsPage from 'pages/pga-coach/settings'
import BookingTypesPage from 'pages/pga-coach/bookings/booking-types'
import NewBookingType from 'pages/pga-coach/bookings/booking-types/new'
import NewBookingTypeOnboardingPage from 'pages/pga-coach/bookings/booking-types/new/onboarding'
import EditBookingType from 'pages/pga-coach/bookings/booking-types/edit'
import AvailabilityPage from 'pages/pga-coach/settings/availability'
import AvailabilityOnboardingPage from 'pages/pga-coach/settings/availability/onboarding'
import BlockedTimesPage from 'pages/pga-coach/settings/blocked-times'
import BookingLimitsPage from 'pages/pga-coach/settings/booking-limits'
import BookingUrlPage from 'pages/pga-coach/settings/booking-url'
import EventsPage from 'pages/pga-coach/bookings/events'
import EditEvent from 'pages/pga-coach/bookings/events/edit'
import ScheduleOnboarding from 'pages/pga-coach/bookings/schedule-onboarding'
import CoachToolsBannerAd from 'components/smart-banner-ads/coach-tools-banner-ad'
import ActivityPlansPage from 'pages/pga-coach/activity-plans'
import StagePage from 'pages/pga-coach/activity-plans/stage'
import StudentShowPage from 'pages/pga-coach/students/student-show-page'
import TrainingAndResourcesPage from 'pages/pga-coach/training-and-resources'
import { flags, useFeatureFlags } from '../lib/feature-flags'
import PGAHopeStudent from 'pages/pga-coach/students/student-show-page/pga-hope'
import LeadIndexPage from 'pages/pga-coach/students/lead-show-page'
import LightningFastOnboarding from 'pages/pga-coach/lightning-fast-onboarding'
import LightningEventCreate from 'components/lightning-fast-onboarding/create-lightning-event-form-wizard'
import StudentEditPage from 'pages/pga-coach/students/student-edit-page'
import { useGetLeads } from 'pages/pga-coach/students/api-hooks.js'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import CoachBusinessReportsDashboard from 'pages/coach-business-insights-reports/coach-insights'
import Reports from 'pages/coach-business-insights-reports/reports'
import CreateReport from 'pages/coach-business-insights-reports/create-report'

const useStyles = makeStyles(() => ({
  badge: {
    right: '-2rem',
    top: '50%',
  },
}))

const PgaCoachRoutes = () => {
  const { path } = useRouteMatch()
  const { user } = useAuth()
  const classes = useStyles()
  const { hideSensitiveInformation } = user

  const { loading, data: leadsData } = useGetLeads()
  const [isAppInstallBannerAdEnabled] = useFeatureFlags([
    flags.FLAG_APP_INSTALL_BANNER_AD,
  ])
  const [isCoachBusinessInsightsEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_COACH_BUSINESS_INSIGHTS,
  ])

  const facilitiesPath = `${path}/settings/facilities`

  const [unviewedLeadCount, setUnviewedLeadCount] = useState(0)

  useEffect(() => {
    if (!loading && leadsData) {
      setUnviewedLeadCount(
        leadsData.coachingInquiries.filter(lead => lead.viewedAt === null)
          .length,
      )
    }
  }, [loading, leadsData])

  const menuItems = [
    {
      icon: <HomeOutlinedIcon />,
      label: 'Dashboard',
      path: `${path}`,
      exact: true,
    },
    {
      icon: <PersonOutlineOutlinedIcon />,
      label: 'Coach Profile',
      path: `${path}/profile`,
    },
    {
      icon: <PermContactCalendarOutlinedIcon />,
      label: (
        <Badge
          color="error"
          badgeContent={unviewedLeadCount}
          classes={{ anchorOriginTopRightRectangular: classes.badge }}
        >
          Students
        </Badge>
      ),
      path: `${path}/students/list`,
    },
    {
      icon: <EventAvailableOutlinedIcon />,
      label: 'Schedule',
      path: `${path}/bookings/schedule`,
      exact: true,
    },
    {
      icon: <AddToPhotosOutlinedIcon />,
      label: 'My Offerings',
      exact: true,
      path: `${path}/bookings/offerings`,
    },
    {
      icon: <MessageOutlinedIcon />,
      label: 'Messaging',
      path: `${path}/messages`,
      hide: hideSensitiveInformation,
    },
    {
      icon: <SettingsOutlinedIcon />,
      label: 'Settings',
      path: `${path}/settings`,
    },
    ...(isCoachBusinessInsightsEnabled
      ? [
        {
          icon: <BarChartOutlinedIcon />,
          label: 'Performance Reports',
          path: `${path}/reports`,
        },
      ]
      : []),
    {
      icon: <SchoolOutlinedIcon />,
      label: 'Training & Resources',
      path: `${path}/training-resources`,
    },
  ]

  /*
  This is used as work-around related to Intercom Product Tours
  More Details: https://www.intercom.com/help/en/articles/3049411-why-can-t-i-build-a-product-tour#h_2b0ef1dcd9
  */
  const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>PGA Coach</title>
        <meta name="description" content="PGA Coach" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {isAppInstallBannerAdEnabled && <CoachToolsBannerAd />}
        <Shell
          logo={{
            src: logo,
            alt: 'PGA logo',
            width: 'auto',
            height: '36px',
            mobileHeight: '28px',
          }}
          menuItems={menuItems}
          unviewedLeadCount={unviewedLeadCount}
        >
          <Switch>
            <Route exact path={path} component={CoachDashboard} />
            <Route
              exact
              path={`${path}/messages/:externalId/new-conversation`}
              component={Messages}
            />
            <Route
              exact
              path={`${path}/messages/:channelId?`}
              component={Messages}
            />
            <Route
              exact
              path={`${path}/profile/edit`}
              component={EditProfilePage}
            />
            <Route path={`${path}/profile`} component={CoachProfileIndexPage} />
            <Redirect from={`${path}/reviews`} to={`${path}/profile/reviews`} />
            <Route
              exact
              path={`${path}/settings`}
              component={CoachSettingsPage}
            />
            <Route
              exact
              path={`${path}/settings/availability`}
              component={AvailabilityPage}
            />
            <Route
              exact
              path={`${path}/settings/blocked-times`}
              component={BlockedTimesPage}
            />
            <Route
              exact
              path={`${path}/settings/booking-limits`}
              component={BookingLimitsPage}
            />
            <Route
              exact
              path={`${path}/settings/booking-url`}
              component={BookingUrlPage}
            />
            <Route
              exact
              path={`${path}/bookings/booking-types`}
              component={BookingTypesPage}
            />
            <Route
              exact
              path={`${path}/bookings/booking-types/new`}
              component={() => <NewBookingType />}
            />
            <Route
              exact
              path={`${path}/bookings/booking-types/edit/:bookingTypeId`}
              component={() => <EditBookingType />}
            />
            <Route
              exact
              path={`${path}/bookings/events`}
              component={() => <EventsPage />}
            />
            {isCoachBusinessInsightsEnabled && (
              <Route
                exact
                path={`${path}/reports/business-insights/sample/:sampleId`}
                component={CoachBusinessReportsDashboard}
              />
            )}
            {isCoachBusinessInsightsEnabled && (
              <Route
                exact
                path={`${path}/reports/business-insights/:reportId`}
                component={CoachBusinessReportsDashboard}
              />
            )}
            {isCoachBusinessInsightsEnabled && (
              <Route exact path={`${path}/reports`} component={Reports} />
            )}
            {isCoachBusinessInsightsEnabled && (
              <Route
                exact
                path={`${path}/reports/create-report`}
                component={CreateReport}
              />
            )}
            {isCoachBusinessInsightsEnabled && (
              <Route
                exact
                path={`${path}/reports/edit-report/:reportId`}
                component={CreateReport}
              />
            )}
            <Route
              exact
              path={`${path}/reports/business-insights/:reportId`}
              component={CoachBusinessReportsDashboard}
            />
            <Route
              exact
              path={`${path}/reports/business-insights`}
              component={CoachBusinessReportsDashboard}
            />
            <Route exact path={`${path}/reports`} component={Reports} />
            <Route
              exact
              path={`${path}/reports/create-report`}
              component={CreateReport}
            />
            <Route
              exact
              path={`${path}/reports/edit-report/:reportId`}
              component={CreateReport}
            />
            <Route
              exact
              path="/pga-coach/bookings/events/edit/:slug"
              component={() => <EditEvent />}
            />
            <Redirect
              from={`${path}/facilities`}
              to={`${path}/settings/facilities`}
            />
            <Route
              exact
              path={`${facilitiesPath}`}
              component={FacilitiesPage}
            />
            <Route
              exact
              path={`${facilitiesPath}/new`}
              component={FacilitiesPageNew}
            />
            <Route
              exact
              path="/pga-coach/onboarding/facilities/new"
              component={FacilityOnboardingPage}
            />
            <Route
              exact
              path={`${path}/onboarding/availability`}
              component={AvailabilityOnboardingPage}
            />
            <Route
              exact
              path={`${path}/onboarding/bookings/booking-types/new`}
              component={() => <NewBookingTypeOnboardingPage />}
            />
            <Route
              exact
              path={`${facilitiesPath}/:id`}
              component={FacilitiesPageShow}
            />
            <Route
              exact
              path={`${facilitiesPath}/:id/edit`}
              component={FacilitiesPageEdit}
            />
            <Route path={`${path}/bookings`} component={BookingsPage} />
            <Route
              exact
              path={`${path}/bookings/offerings`}
              component={OfferingsPage}
            />
            <Redirect
              from={`${path}/students/leads/:id`}
              to={`${path}/leads/:id`}
            />
            <Route
              path={`${path}/students`}
              component={() => (
                <StudentsPage setUnviewedLeadCount={setUnviewedLeadCount} />
              )}
            />
            <Route
              path={`${path}/student/:studentId`}
              exact
              component={() => <StudentShowPage />}
            />
            <Route
              path={`${path}/student/:studentId/edit`}
              exact
              component={() => <StudentEditPage />}
            />
            <Route
              path={`${path}/training-resources/activity-plans/:stage`}
              component={StagePage}
            />
            <Route
              path={`${path}/leads/:leadId`}
              exact
              component={() => <LeadIndexPage />}
            />
            <Route
              exact
              path={`${path}/fast-onboarding/new`}
              component={LightningEventCreate}
            />
            <Route
              path={`${path}/fast-onboarding`}
              exact
              component={LightningFastOnboarding}
            />
            <Route
              path={`${path}/set-up-schedule`}
              exact
              component={ScheduleOnboarding}
            />
            <Route
              path={`${path}/training-resources`}
              exact
              component={TrainingAndResourcesPage}
            />
            <Redirect
              from={`${path}/activity-plans`}
              to={`${path}/training-resources/activity-plans`}
            />
            <Route
              path={`${path}/training-resources/activity-plans`}
              exact
              component={() => <ActivityPlansPage />}
            />
            <Route
              path={`${path}/:eventSlug/:studentId`}
              exact
              component={() => <PGAHopeStudent />}
            />
            <Route
              path={`${path}/*`}
              render={() => <div>Page Not Found</div>}
            />
          </Switch>
        </Shell>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(PgaCoachRoutes, roles.COACH)
