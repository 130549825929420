import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Typography, TextField, Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { formatCurrencyInput, formatFromIntToCurrency } from 'utils/number'
import FormLoadingSkeleton from '../loading-skeleton'
import { useMutation, useQuery } from '@apollo/client'
import { UpsertBusinessGoalMutation, GetBusinessGoalsByReportIdQuery } from '../query'
import { useSnackbar } from 'notistack'
import ConfirmationDialog from '../save-changes-modal'
import { useStepNavigation } from 'lib/hooks/useStepNavigation'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'

const CoachingGoals = forwardRef(({ loading: parentLoading, onNext, onBack, report, onFormChange, isEditMode }, ref) => {
  const { enqueueSnackbar } = useSnackbar()
  const [upsertBusinessGoal] = useMutation(UpsertBusinessGoalMutation)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldFetchGoals, setShouldFetchGoals] = useState(false)

  // Wait for the report.id to be available
  useEffect(() => {
    if (report?.id) {
      setShouldFetchGoals(true)
    }
  }, [report])

  // Query to get business goals when editing
  const { data: businessGoalsData, loading: businessGoalsLoading } = useQuery(
    GetBusinessGoalsByReportIdQuery,
    {
      variables: { reportId: report?.id },
      skip: !shouldFetchGoals,
      onError: (error) => {
        console.error('Error fetching business goals:', error)
      },
    },
  )

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      avgMonthlyRevenue: '',
      avgLeads: '',
      avgCoachingHours: '',
    },
    mode: 'onChange',
  })

  // Notify form changes
  useEffect(() => {
    if (isDirty) {
      onFormChange?.()
    }
  }, [isDirty, onFormChange])

  // Effect to load previous data if available
  useEffect(() => {
    if (businessGoalsData?.businessGoals) {
      const goals = businessGoalsData.businessGoals

      // Convert the value to string before formatting
      const monthlyRevenue = goals.revenuePerMonthInCents / 100
      setValue('avgMonthlyRevenue', formatFromIntToCurrency(monthlyRevenue.toString()))
      setValue('avgLeads', goals.leadsPerMonth)
      setValue('avgCoachingHours', goals.hoursPerMonth)
    }
  }, [businessGoalsData, setValue])

  const avgMonthlyRevenue = watch('avgMonthlyRevenue')

  const handleCurrencyChange = (name) => (event) => {
    const formattedValue = formatCurrencyInput(event.target.value)
    setValue(name, formattedValue, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const handleBlur = (name) => (event) => {
    if (event.target.value) {
      const formattedValue = formatFromIntToCurrency(event.target.value)
      setValue(name, formattedValue, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      setIsLoading(true)
      if (isDirty) {
        const formData = watch()
        const monthlyRevenue = formData.avgMonthlyRevenue
          ? parseFloat(formData.avgMonthlyRevenue.replace(/[$,]/g, ''))
          : 0

        await upsertBusinessGoal({
          variables: {
            reportId: report.id,
            input: {
              hoursPerMonth: formData.avgCoachingHours ? parseInt(formData.avgCoachingHours) : 0,
              leadsPerMonth: formData.avgLeads ? parseInt(formData.avgLeads) : 0,
              revenuePerYearInCents: Math.round(monthlyRevenue * 100) * 12,
              workingMonths: 12,
            },
          },
        })
      }
      setIsLoading(false)
    },
  }))

  const handleNext = async (data) => {
    setIsLoading(true)
    try {
      if (isDirty) {
        const monthlyRevenue = data.avgMonthlyRevenue
          ? parseFloat(data.avgMonthlyRevenue.replace(/[$,]/g, ''))
          : 0

        await upsertBusinessGoal({
          variables: {
            reportId: report.id,
            input: {
              hoursPerMonth: data.avgCoachingHours ? parseInt(data.avgCoachingHours) : 0,
              leadsPerMonth: data.avgLeads ? parseInt(data.avgLeads) : 0,
              revenuePerYearInCents: Math.round(monthlyRevenue * 100) * 12,
              workingMonths: 12,
            },
          },
        })
      }
      onNext()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const { showSaveModal, setShowSaveModal, handleNavigation } = useStepNavigation({
    isEditMode,
    report,
    onNext,
    onBack,
    isDirty,
    handleSubmit,
    handleNext,
  })

  const onSubmit = (data) => {
    if (isEditMode && isDirty) {
      setShowSaveModal(true)
      return
    }
    handleNext(data)
  }

  if (parentLoading || !report?.id || businessGoalsLoading) {
    return <FormLoadingSkeleton type="coaching" />
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontSize: '34px', lineHeight: '42px', fontWeight: 500 }}
        >
          Monthly Coaching Goals
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: 3, fontSize: '16px', lineHeight: '24px', color: '#0000008A' }}
        >
          Enter average monthly goals to establish clear benchmarks for evaluating your performance.
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextField
            {...register('avgMonthlyRevenue')}
            label="Avg Monthly Revenue"
            value={avgMonthlyRevenue || ''}
            onChange={handleCurrencyChange('avgMonthlyRevenue')}
            onBlur={handleBlur('avgMonthlyRevenue')}
            InputProps={{
              startAdornment: <span>$</span>,
            }}
            error={!!errors.avgMonthlyRevenue}
            helperText={errors.avgMonthlyRevenue?.message}
            fullWidth
          />
          <TextField
            {...register('avgLeads')}
            label="Avg # New Leads Per Month"
            type="number"
            value={watch('avgLeads') || ''}
            onChange={(e) => setValue('avgLeads', e.target.value, { shouldDirty: true })}
            error={!!errors.avgLeads}
            helperText={errors.avgLeads?.message}
            fullWidth
          />
          <TextField
            {...register('avgCoachingHours')}
            label="Avg # Hours Coaching Per Month"
            type="number"
            value={watch('avgCoachingHours') || ''}
            onChange={(e) => setValue('avgCoachingHours', e.target.value, { shouldDirty: true })}
            error={!!errors.avgCoachingHours}
            helperText={errors.avgCoachingHours?.message}
            fullWidth
          />

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 2 }}>
            <Button
              onClick={() => handleNavigation(true, 'coaching-goals')}
              variant="outlined"
              color="primary"
            >
              {isEditMode ? 'Cancel' : 'Back'}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={16} />}
            >
              Save and Continue
            </Button>
          </Box>
        </Box>
      </form>

      <ConfirmationDialog
        open={showSaveModal}
        onClose={() => {
          setShowSaveModal(false)
        }}
        onConfirm={() => {
          handleSubmit(handleNext)()
          setShowSaveModal(false)
        }}
      />
    </>
  )
})

export default CoachingGoals
