import React from 'react'
import { connectHighlight } from 'react-instantsearch-dom'

function Highlight({ highlight, attribute: attributePath, hit }) {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute: attributePath,
    preTag: '<em>',
    postTag: '</em>',
    hit,
  })

  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <span key={index}>{part.value}</span>
        ) : (
          <strong key={index}>{part.value}</strong>
        ),
      )}
    </span>
  )
}

const CustomHighlight = connectHighlight(Highlight)

export default CustomHighlight
