import gql from 'graphql-tag'

export const GetFacilityConstantsQuery = gql`
  query FacilityConstants($reportId: ID!) {
    facilityConstants(reportId: $reportId) {
      avgDrivingRangeSpendInCents
      avgFbSpendInCents
      avgMerchandiseSpendInCents
      avgOtherSpendInCents
      facilityId
      id
      membershipCostInCents
      reportId
    }
  }
`

export const CreateReportMutation = gql`
  mutation CreateReport($input: ReportInput!) {
    createReport(input: $input) {
      code
      message
      success
      report {
        id
        name
        facilityId
        coachId
      }
    }
  }
`

export const UpsertFacilityConstantMutation = gql`
  mutation UpsertFacilityConstant(
    $input: FacilityConstantInput!
    $facilityId: ID!
    $reportId: ID!
  ) {
    upsertFacilityConstant(
      facilityId: $facilityId
      reportId: $reportId
      input: $input
    ) {
      code
      message
      success
    }
  }
`

export const UpsertMonthlyInputMutation = gql`
  mutation UpsertMonthlyMetric(
    $input: MonthlyMetricInput!
    $facilityId: ID!
    $reportId: ID!
    $month: Int!
  ) {
    upsertMonthlyMetric(
      facilityId: $facilityId
      reportId: $reportId
      month: $month
      input: $input
    ) {
      code
      message
      success
    }
  }
`

export const GetFacilityQuery = gql`
  query Facility($facilityId: ID!) {
    facility(facilityId: $facilityId) {
      id
    }
  }
`

export const UpsertBusinessGoalMutation = gql`
  mutation UpsertBusinessGoal($reportId: ID!, $input: BusinessGoalInput!) {
    upsertBusinessGoal(reportId: $reportId, input: $input) {
      code
      message
      success
    }
  }
`

export const GetBusinessGoalsByReportIdQuery = gql`
  query BusinessGoals($reportId: ID!) {
    businessGoals(reportId: $reportId) {
      id
      reportId
      hoursPerMonth
      leadsPerMonth
      revenuePerMonthInCents
      revenuePerYearInCents
      workingMonths
      effectiveHourlyRateInCents
    }
  }
`

export const GetMonthlyMetricsByReportIdQuery = gql`
  query MonthlyMetrics($reportId: ID!) {
    monthlyMetrics(reportId: $reportId) {
      adminHours
      coachingHours
      directPaymentToFacilityInCents
      facilityId
      id
      marketingSpendInCents
      month
      numberOfContacts
      numberOfLeads
      numberOfNewStudents
      numberOfStudentsThatTookLessons
      otherExpensesInCents
      purchasesFromFacilityInCents
      revenueInCents
      stringMonth
      studentsConvertedToMembers
      totalNumberOfStudents
    }
  }
`
