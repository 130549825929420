import React from 'react'
import { Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'
import PersonIcon from '@material-ui/icons/Person'
import PinDropIcon from '@material-ui/icons/PinDrop'
import clsx from 'clsx'
import { PlaceSelection, FacilitySelection, CoachSelection } from '../selection'

export const types = {
  PLACE: 'place',
  FACILITY: 'facility',
  COACH: 'coach',
}

const useStyles = makeStyles(theme => {
  const circleSize = theme.spacing(7)

  return {
    icon: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(2),
      },
    },

    circle: {
      alignItems: 'center',
      borderColor: theme.palette.secondary.main,
      borderRadius: circleSize,
      borderStyle: 'solid',
      borderWidth: 1,
      display: 'flex',
      justifyContent: 'center',
      minHeight: circleSize,
      minWidth: circleSize,
    },

    content: {
      fontSize: 14,
      marginTop: theme.spacing(-0.5),

      [theme.breakpoints.up('md')]: {
        fontSize: 16,
        marginTop: 0,
      },
    },
  }
})

function Content({ IconComponent, children, encircleIcon = false }) {
  const classes = useStyles()
  const Icon = encircleIcon
    ? () => (
      <>
        <Hidden smDown>
          <div className={clsx(classes.icon, classes.circle)}>
            <IconComponent />
          </div>
        </Hidden>
        <Hidden mdUp>
          <IconComponent className={classes.icon} />
        </Hidden>
      </>
    )
    : () => <IconComponent className={classes.icon} />

  return (
    <>
      <Icon />

      <div className={classes.content}>{children}</div>
    </>
  )
}

class Hit {
  constructor(hit) {
    this.hit = hit
    this.hit._geoloc = hit._geoloc || {}
  }
}

export class PlaceHit extends Hit {
  get type() {
    return types.PLACE
  }

  get label() {
    const {
      locale_names: [city],
      administrative: [state],
    } = this.hit

    return `${city}, ${state}`
  }

  get uniqueKey() {
    return `${this.type}-${this.label}`
  }

  renderHighlightedContent(CustomHighlight, classes) {
    return (
      <Content IconComponent={PinDropIcon}>
        <div>
          <span>
            <span>
              {this.hit.locale_names[0].substring(0, this.hit.matchLength)}
            </span>
            <strong>
              {this.hit.locale_names[0].substring(this.hit.matchLength)}
            </strong>
          </span>
          ,&nbsp;
          <span>{this.hit.administrative[0]}</span>
        </div>
      </Content>
    )
  }

  toSelection() {
    const {
      _geoloc: { lat, lng },
    } = this.hit

    return new PlaceSelection({ label: this.label, lat, lng })
  }
}

export class FacilityHit extends Hit {
  get type() {
    return types.FACILITY
  }

  get label() {
    const { facility_name: facilityName, city, state } = this.hit
    return `${facilityName}, ${city}, ${state}`
  }

  get uniqueKey() {
    return `${this.type}-${this.label}`
  }

  renderHighlightedContent(CustomHighlight, classes) {
    return (
      <Content IconComponent={GolfCourseIcon} encircleIcon>
        <div>
          <CustomHighlight hit={this.hit} attribute="facility_name" />
          ,&nbsp;
        </div>

        <div>
          <CustomHighlight hit={this.hit} attribute="city" />
          ,&nbsp;
          <CustomHighlight hit={this.hit} attribute="state" />
        </div>
      </Content>
    )
  }

  toSelection() {
    const {
      _geoloc: { lat, lng },
    } = this.hit

    return new FacilitySelection({ label: this.label, lat, lng })
  }
}

export class CoachHit extends Hit {
  get type() {
    return types.COACH
  }

  get label() {
    return this.hit.coach_name
  }

  get uniqueKey() {
    const { hit } = this

    return [this.type, hit.coach_name, hit.city, hit.state].join('-')
  }

  renderHighlightedContent(CustomHighlight, classes) {
    return (
      <Content IconComponent={PersonIcon} encircleIcon>
        <div>
          <CustomHighlight hit={this.hit} attribute="coach_name" />
        </div>

        <div>
          <CustomHighlight hit={this.hit} attribute="city" />
          ,&nbsp;
          <CustomHighlight hit={this.hit} attribute="state" />
        </div>
      </Content>
    )
  }

  toSelection() {
    return new CoachSelection({ query: this.hit.coach_name })
  }
}
