import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as Magnifier } from 'images/event-search/magnifier.svg'
import Divider from '@material-ui/core/Divider'
import SurveyForm from 'components/program-marketing/survey-form'

const defaultHelperText =
  'Try adjusting your search filters such as radius to view additional results.'

export default function NoResults({
  surveyName,
  helperText = defaultHelperText,
}) {
  return (
    <Container maxWidth="md">
      <Paper>
        <Box display="flex" flexDirection="column" p={8} alignItems="center">
          <Magnifier />

          <Box m={1}>
            <Typography variant="h5">No Results Found</Typography>
          </Box>

          <Box m={1}>
            <Typography variant="body1">{helperText}</Typography>
          </Box>
          {surveyName && (
            <>
              <Box m={2} width="100%">
                <Divider />
              </Box>
              <Container maxWidth="sm">
                <Box m={2}>
                  <Typography variant="h5" align="center">
                    Get notified about new programs near you!
                  </Typography>
                  <Typography variant="body1" align="center">
                    We’re adding new events every day. Sign up to receive
                    updates when new events launch in your area.
                  </Typography>
                </Box>
                <SurveyForm surveyName={surveyName} eventsName="programs" />
              </Container>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  )
}
