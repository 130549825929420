import React, { useState } from 'react'
import { Box, Typography, Grid, ToggleButton, TextField, ToggleButtonGroup, Tabs, Tab, Paper } from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'
import { UpsertMonthlyInputMutation, GetMonthlyMetricsByReportIdQuery } from '../query'
import { useSnackbar } from 'notistack'
import CheckIcon from '@mui/icons-material/Check'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import {
  mobileTabsContainerStyles,
  tabsStyles,
  monthSelectionPaperStyles,
  toggleButtonGroupStyles,
  nextMonthPaperStyles,
  nextMonthIconContainerStyles,
  nextMonthIconStyles,
  nextMonthTextStyles,
  introTextStyles,
  checkIconStyles,
} from './styles'
import FormLoadingSkeleton from '../loading-skeleton'
import ConfirmationDialog from '../save-changes-modal'
import { useStepNavigation } from 'lib/hooks/useStepNavigation'
import Currency from 'components/form-util/currency'
import { convertToCents } from 'utils/number'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'

const MONTHS = [
  { value: 'Jan', label: 'Jan' },
  { value: 'Feb', label: 'Feb' },
  { value: 'Mar', label: 'Mar' },
  { value: 'Apr', label: 'Apr' },
  { value: 'May', label: 'May' },
  { value: 'Jun', label: 'Jun' },
  { value: 'Jul', label: 'Jul' },
  { value: 'Aug', label: 'Aug' },
  { value: 'Sept', label: 'Sept' },
  { value: 'Oct', label: 'Oct' },
  { value: 'Nov', label: 'Nov' },
  { value: 'Dec', label: 'Dec' },
]

const MonthlyInputsComp = ({ monthlyInputsToEdit, onNext, onBack, report, isEditMode, parentLoading }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [upsertMonthlyInput] = useMutation(UpsertMonthlyInputMutation)
  const [selectedMonth, setSelectedMonth] = useState('Jan')
  const [monthlyData, setMonthlyData] = useState(monthlyInputsToEdit)
  const [isLoading, setIsLoading] = useState(false)

  const handleNext = async () => {
    setIsLoading(true)
    try {
      const monthsToSubmit = Object.entries(monthlyData)
        .filter(([key, monthData]) => Object.values(monthData).some((value) => value !== '') && JSON.stringify(monthData) !== JSON.stringify(monthlyInputsToEdit[key]))

      await Promise.all(monthsToSubmit.map(async ([month, monthData]) => {
        const monthIndex = MONTHS.findIndex((m) => m.value === month) + 1
        const numberOfNewStudents = parseInt(monthData.numberOfNewStudents) || 0
        const numberOfStudentsThatTookLessons = parseInt(monthData.numberOfStudentsThatTookLessons) || 0
        const numberOfLeads = parseInt(monthData.numberOfLeads) || 0

        return upsertMonthlyInput({
          variables: {
            facilityId: report.facilityId,
            reportId: report.id,
            month: monthIndex,
            input: {
              coachingHours: parseInt(monthData.coachingHours) || 0,
              adminHours: parseInt(monthData.adminHours) || 0,
              numberOfLeads,
              numberOfStudentsThatTookLessons,
              numberOfNewStudents,
              studentsConvertedToMembers: parseInt(monthData.studentsConvertedToMembers) || 0,
              directPaymentToFacilityInCents: convertToCents(monthData.directPaymentToFacilityInCents),
              purchasesFromFacilityInCents: convertToCents(monthData.purchasesFromFacilityInCents),
              otherExpensesInCents: convertToCents(monthData.otherExpensesInCents),
              revenueInCents: convertToCents(monthData.revenueInCents),
              marketingSpendInCents: convertToCents(monthData.marketingSpendInCents),
              totalNumberOfStudents: numberOfNewStudents + numberOfStudentsThatTookLessons,
              numberOfContacts: numberOfLeads + numberOfNewStudents,
            },
          },
        })
      }))
      onNext()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const renderMonthTab = (month) => (
    <Tab
      key={month.value}
      value={month.value}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {monthlyData[month.value]?.saved && (
            <CheckIcon sx={{ mr: 0.5, fontSize: '1rem' }} />
          )}
          {month.label}
        </Box>
      }
    />
  )

  const handleSave = (event) => {
    event.preventDefault()
    if (isEditMode && JSON.stringify(monthlyData) !== JSON.stringify(monthlyInputsToEdit)) {
      setShowSaveModal(true)
      return
    }
    handleNext()
  }

  const renderMonthToggleButton = (month) => (
    <ToggleButton key={month.value} value={month.value}>
      {monthlyData[month.value]?.saved && (
        <CheckIcon sx={checkIconStyles} />
      )}
      {month.label}
    </ToggleButton>
  )

  const { showSaveModal, setShowSaveModal, handleNavigation } = useStepNavigation({
    isEditMode,
    report,
    onNext,
    onBack,
    isDirty: JSON.stringify(monthlyData) !== JSON.stringify(monthlyInputsToEdit),
    handleSubmit: handleSave,
    handleNext,
  })

  if (parentLoading || !report?.id) {
    return <FormLoadingSkeleton type="monthly" />
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setMonthlyData((prevData) => ({
      ...prevData,
      [selectedMonth]: {
        ...prevData[selectedMonth],
        [name]: value,
      },
    }))
  }

  const handleNextMonth = () => {
    setMonthlyData((prevData) => ({
      ...prevData,
      [selectedMonth]: {
        ...prevData[selectedMonth],
        saved: true,
      },
    }))
    setSelectedMonth(MONTHS[MONTHS.findIndex((m) => m.value === selectedMonth) + 1].value)
  }

  const handleTabChange = (event, newValue) => {
    if (newValue === selectedMonth || !newValue) {
      return
    }

    if (Object.values(monthlyData[selectedMonth]).some((value) => value !== '' && value !== false)) {
      setMonthlyData((prevData) => ({
        ...prevData,
        [selectedMonth]: {
          ...prevData[selectedMonth],
          saved: true,
        },
      }))
    }
    setSelectedMonth(newValue)
  }

  return (
    <>
      <form onSubmit={handleSave}>
        <Typography variant="h4" gutterBottom>
          Monthly Inputs
        </Typography>

        <Typography variant="body1" sx={introTextStyles}>
          Begin by selecting a month from the list below and inputting your monthly metrics.
          Use the month tabs to add details.
        </Typography>

        <Box sx={mobileTabsContainerStyles}>
          <Tabs
            value={selectedMonth}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="month selection"
            sx={tabsStyles}
          >
            {MONTHS.map(renderMonthTab)}
          </Tabs>
        </Box>

        <Paper sx={monthSelectionPaperStyles}>
          <Box>
            <ToggleButtonGroup
              value={selectedMonth}
              exclusive
              onChange={handleTabChange}
              aria-label="month selection"
              sx={toggleButtonGroupStyles}
            >
              {MONTHS.map(renderMonthToggleButton)}
            </ToggleButtonGroup>
          </Box>
        </Paper>

        <Typography variant="h5" gutterBottom>
          Coaching Metrics
        </Typography>
        <Typography variant="body2" sx={{ mb: 3 }}>
          Enter your monthly coaching metrics for the selected month.
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={12} sm={12}>
            <Currency
              name={'revenueInCents'}
              label="Total Coaching Revenue"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.revenueInCents}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name={'coachingHours'}
              label="Coaching Hours"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.coachingHours}
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name={'adminHours'}
              label="Administrative Hours"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.adminHours}
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Currency
              name={'purchasesFromFacilityInCents'}
              label="Purchases to Club"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.purchasesFromFacilityInCents}
              helperText="Supplies, green fees, etc."
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Currency
              name={'directPaymentToFacilityInCents'}
              label="Rent Paid to Club"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.directPaymentToFacilityInCents}
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Currency
              name={'marketingSpendInCents'}
              label="Marketing Spend"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.marketingSpendInCents}
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Currency
              name={'otherExpensesInCents'}
              label="Other Expenses"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.otherExpensesInCents}
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name={'numberOfStudentsThatTookLessons'}
              label="Unique Students"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.numberOfStudentsThatTookLessons}
              fullWidth
              helperText="Total unique students coached this month"
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name={'numberOfNewStudents'}
              label="New Students"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.numberOfNewStudents}
              helperText="New students coached this month"
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name={'numberOfLeads'}
              label="New Leads"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.numberOfLeads}
              helperText="Number of new leads"
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name={'studentsConvertedToMembers'}
              label="Number of Conversions"
              onChange={handleInputChange}
              value={monthlyData[selectedMonth]?.studentsConvertedToMembers}
              helperText="Students converted to facility members"
              fullWidth
              type="number"
            />
          </Grid>

          <Grid item xs={12}>
            <Paper sx={nextMonthPaperStyles}>
              <Box sx={nextMonthIconContainerStyles}>
                <EventAvailableIcon sx={nextMonthIconStyles} />
                <Typography variant="body2" sx={nextMonthTextStyles}>
                  Add next month's metrics
                </Typography>
              </Box>
              <Button
                onClick={handleNextMonth}
                variant="outlined"
                color="primary"
                disabled={monthlyData[selectedMonth]?.revenueInCents === ''}
              >
                GO TO NEXT MONTH
              </Button>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 2 }}>
          <Button
            onClick={() => handleNavigation(true)}
            variant="outlined"
            color="primary"
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={16} />}
          >
            Save and Continue
          </Button>
        </Box>
      </form>

      <ConfirmationDialog
        open={showSaveModal}
        onClose={() => {
          setShowSaveModal(false)
        }}
        onConfirm={() => {
          handleNext()
          setShowSaveModal(false)
        }}
      />
    </>
  )
}

const MonthlyInputs = ({ loading: parentLoading, onNext, onBack, report, isEditMode }) => {
  // Query to get monthly metrics when editing
  const { data: monthlyMetricsData, loading: monthlyMetricsLoading } = useQuery(
    GetMonthlyMetricsByReportIdQuery,
    {
      variables: { reportId: report?.id },
      skip: !report?.id,
    },
  )

  if (monthlyMetricsLoading || parentLoading) {
    return <FormLoadingSkeleton type="monthly" />
  }

  const blankMonth = {
    revenueInCents: '',
    coachingHours: '',
    adminHours: '',
    purchasesFromFacilityInCents: '',
    directPaymentToFacilityInCents: '',
    marketingSpendInCents: '',
    otherExpensesInCents: '',
    numberOfStudentsThatTookLessons: '',
    numberOfNewStudents: '',
    numberOfLeads: '',
    studentsConvertedToMembers: '',
    saved: false,
  }

  const buildMonthData = (monthIndex) => {
    const monthData = monthlyMetricsData?.monthlyMetrics?.find((m) => m.month === monthIndex)
    if (monthData) {
      return {
        coachingHours: parseInt(monthData.coachingHours) || 0,
        revenueInCents: (monthData.revenueInCents / 100).toFixed(2),
        adminHours: monthData.adminHours,
        purchasesFromFacilityInCents: (monthData.purchasesFromFacilityInCents / 100).toFixed(2),
        directPaymentToFacilityInCents: (monthData.directPaymentToFacilityInCents / 100).toFixed(2),
        marketingSpendInCents: (monthData.marketingSpendInCents / 100).toFixed(2),
        otherExpensesInCents: (monthData.otherExpensesInCents / 100).toFixed(2),
        numberOfStudentsThatTookLessons: monthData.numberOfStudentsThatTookLessons,
        numberOfNewStudents: monthData.numberOfNewStudents,
        numberOfLeads: monthData.numberOfLeads,
        studentsConvertedToMembers: monthData.studentsConvertedToMembers,
        saved: true,
      }
    }
    return blankMonth
  }

  const monthlyInputs = MONTHS.reduce((acc, month, index) => {
    return {
      ...acc,
      [month.value]: buildMonthData(index + 1),
    }
  }, {})

  return <MonthlyInputsComp
    parentLoading={parentLoading}
    onNext={onNext}
    onBack={onBack}
    report={report}
    isEditMode={isEditMode}
    monthlyInputsToEdit={monthlyInputs}
  />
}

export default MonthlyInputs
