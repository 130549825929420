import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core'
import BarChartIcon from '@mui/icons-material/BarChart'
import CloseIcon from '@mui/icons-material/Close'

const BusinessInsightsModal = ({ open, onClose, onGetStarted, classes }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent className={classes.modalContent} disableSpacing>
        <div className={classes.modalHeader}>
          <div className={classes.modalTitleSection}>
            <div className={classes.modalHeaderIcon}>
              <BarChartIcon color="#00234B" />
            </div>
            <Typography variant="h6">
              Get Started with PGA Coach Reports
            </Typography>
          </div>
          <IconButton style={{ padding: '4px' }} onClick={onClose}>
            <CloseIcon fontSize="24px" />
          </IconButton>
        </div>

        <div className={classes.modalBody}>
          <Typography paragraph>
            This tool helps coaches like you gain immediate insights into your
            business health, guiding you toward achieving your annual revenue
            goals. By entering your data, you'll be able to create, view, and
            edit multiple reports through the "Reports" tab, keeping your
            business strategies up-to-date and effective.
          </Typography>

          <Typography variant="h6" gutterBottom>
            My Business Insights
          </Typography>
          <Typography paragraph>
            Dive into your personal business metrics to analyze revenue
            streams, customer engagement, and key performance indicators,
            enabling strategic decisions to boost performance.
          </Typography>

          <Typography variant="h6" gutterBottom>
            My Facility Insights
          </Typography>
          <Typography paragraph>
            Gain a broader perspective on facility health, including usage,
            membership trends, and services, to align your coaching with
            facility goals for shared success.
          </Typography>

          <Typography paragraph>
            Explore these reports to drive your coaching business to new
            heights!
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.modalActions} disableSpacing>
        <Button onClick={onClose}>CANCEL</Button>
        <Button variant="contained" onClick={onGetStarted}>
          GET STARTED
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BusinessInsightsModal
