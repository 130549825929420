import React from 'react'
import { get } from 'lodash'
import { gql, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { ReactComponent as PartyPopperIcon } from 'images/icons/party-popper-outlined.svg'
import ActionTile from 'components/action-tile'
import { useStyles } from './styles'

const GET_AVAILABILITY = gql`
  query CoachAvailability {
    coachAvailabilities {
      coachFacility {
        id
        name
      }
      startDate
      timeZone
      timeSlots {
        day
        startTime
        endTime
      }
    }
  }
`
const OfferingsPage = ({ hasCoachProfile }) => {
  const { data, loading } = useQuery(GET_AVAILABILITY)
  const classes = useStyles()
  const availabilities = get(data, 'coachAvailabilities', [])
  const showSetAvailabilityTooltip =
    !loading && !availabilities?.some(a => a.timeSlots.length > 0)

  return (
    <Container className={classes.root}>
      {showSetAvailabilityTooltip ? (
        <Box className={classes.availabilityWarningBox}>
          <Box display="flex" alignItems="center">
            <ErrorOutlineIcon color="primary" style={{ marginRight: '10px' }} />
            <Typography
              variant="body1"
              className={classes.availabilityWarningText}
            >
              Looks like you haven't set your availability at each of your
              locations yet. We recommend doing that first before creating your
              coach offerings.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/pga-coach/settings/availability"
            >
              Set Availability
            </Button>
          </Box>
        </Box>
      ) : null}
      <Box className={classes.tileButtonContainer}>
        {hasCoachProfile ? (
          <ActionTile
            icon={LibraryBooksIcon}
            headlineText="Booking Type"
            captionText="Booking Types are offerings for **individuals**. Some examples: private coaching sessions, club fittings, swing evaluations, and lesson packs."
            callToAction={
              <Button
                component={Link}
                to="/pga-coach/bookings/booking-types"
                variant="contained"
                color="primary"
                data-cy="see-coach-booking-types"
              >
                Go To Booking Types
              </Button>
            }
          />
        ) : (
          <ActionTile
            className={classes.disabledActionTile}
            icon={LibraryBooksIcon}
            headlineText="Booking Type"
            captionText={
              'Before publishing your booking type, please make sure you have **first created your Coach Profile**.'
            }
            callToAction={
              <Button
                component={Link}
                to="/pga-coach/profile"
                variant="contained"
                color="primary"
                data-cy="see-coach-booking-types"
              >
                Go To Coach Profile
              </Button>
            }
          />
        )}
        <ActionTile
          icon={PartyPopperIcon}
          headlineText="Group Event"
          captionText="Group Events are offerings for **groups**, like after school clinics, Get Golf Ready, supervised practice, and boot camps."
          callToAction={
            <Button
              component={Link}
              to="/pga-coach/bookings/events"
              variant="contained"
              color="primary"
              data-cy="see-coach-events"
            >
              Go To Events
            </Button>
          }
        />
      </Box>
    </Container>
  )
}

export default OfferingsPage
