import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'
import SettingsBox from 'components/coach-settings-box'
import { PageHeader } from 'components/page-header'
import { usePortal } from 'lib/portal'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined'
import { GolferIcon } from 'components/icons/refreshed-icons'
import { PeopleAltOutlined } from '@material-ui/icons'
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined'
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined'

const resourcesBlocks = [
  {
    link:
      '/pga-coach/training-resources/activity-plans/fundamental-movement-skills/activities',
    icon: <GolferIcon size={20} color="primary" />,
    title: 'Fundamental Movement Skills',
    subtitle:
      'Part of coaching the complete golfer involves creating physically literate athletes. Use these movement skill assessments and coaching queues to see where your golfers stand and help them improve.',
  },
  {
    link: 'training-resources/activity-plans',
    icon: <EventNoteOutlinedIcon />,
    title: 'Activity Plans',
    subtitle:
      'Want fresh ideas for your programs? Check out this collection of featured activities and lesson plans with developmentally appropriate things to do with your athletes.',
  },
]

const externalResourcesBlocks = [
  {
    link: 'https://sites.google.com/pgahq.com/adm-communication-guide',
    icon: <PeopleAltOutlined />,
    title: 'ADM Parent Communication',
    subtitle:
      'Inspired by ADM, but need help explaining it to your athletes, parents, and caregivers? These email templates, videos, and stock images can help!',
  },
  {
    link:
      'https://support.pga.com/en/articles/10365690-pga-coach-digital-playbook',
    icon: <MenuBookOutlinedIcon />,
    title: 'Digital Playbook',
    subtitle:
      'Access digital tools, strategies, and best practices to elevate your coaching business online.',
  },
]

const TrainingAndResourcesPage = () => {
  const [target] = usePortal()
  const styles = useStyles()

  const trainingBlocks = [
    {
      link:
        'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/trainingCenter/',
      icon: <SchoolOutlinedIcon />,
      title: 'Coach Training Center',
      subtitle:
        'PGA Coach offers a training center featuring diverse courses designed to elevate your coaching journey and empower you to become the best coach possible.',
      action: <OpenInNewIcon className={styles.action} />,
    },
  ]

  const helpCenterBlocks = [
    {
      link: 'https://support.pga.com/en/collections/2683103-pga-members',
      icon: <ThreePOutlinedIcon />,
      title: 'PGA Coach Help Center',
      subtitle:
        'Need help with PGA Coach? Visit the Help Center to find answers to your questions and learn how to use the platform.',
      action: <OpenInNewIcon className={styles.action} />,
    },
  ]

  return (
    <>
      <PageHeader title="Training & Resources" actions={target} />
      <Container className={styles.root}>
        <Typography variant="h6" gutterBottom className={styles.heading}>
          Training
        </Typography>
        <Grid container spacing={2}>
          {trainingBlocks.map(block => (
            <Grid item lg={7} md={8} xs={12} key={block.title}>
              <a
                className={styles.mainWrapper}
                href={block.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SettingsBox
                  icon={<div className={styles.icon}>{block.icon}</div>}
                  title={block.title}
                  subtitle={block.subtitle}
                  action={block.action}
                />
              </a>
            </Grid>
          ))}
          <Grid item lg={7} md={8} xs={12}>
            <Typography variant="h6" className={styles.heading}>
              Resources
            </Typography>
          </Grid>
          {resourcesBlocks.map(block => (
            <Grid item lg={7} md={8} xs={12} key={block.title}>
              <RouterLink className={styles.mainWrapper} to={block.link}>
                <SettingsBox
                  icon={<div className={styles.icon}>{block.icon}</div>}
                  title={block.title}
                  subtitle={block.subtitle}
                  action={<ChevronRightIcon className={styles.action} />}
                />
              </RouterLink>
            </Grid>
          ))}
          {externalResourcesBlocks.map(block => (
            <Grid item lg={7} md={8} xs={12} key={block.title}>
              <a
                className={styles.mainWrapper}
                href={block.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SettingsBox
                  icon={<div className={styles.icon}>{block.icon}</div>}
                  title={block.title}
                  subtitle={block.subtitle}
                  action={<OpenInNewIcon className={styles.action} />}
                />
              </a>
            </Grid>
          ))}
          <Grid item lg={7} md={8} xs={12}>
            <Typography variant="h6" className={styles.heading}>
              Help Center
            </Typography>
          </Grid>
          {helpCenterBlocks.map(block => (
            <Grid item lg={7} md={8} xs={12} key={block.title}>
              <a
                className={styles.mainWrapper}
                href={block.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SettingsBox
                  icon={<div className={styles.icon}>{block.icon}</div>}
                  title={block.title}
                  subtitle={block.subtitle}
                  action={block.action}
                />
              </a>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default TrainingAndResourcesPage
