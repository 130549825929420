import React from 'react'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useStyles from './styles'
import EmptyPageState from 'components/empty-page-state'
import PartyPopperIcon from 'components/icons/party-popper-icon'
import { InstantSearch, Configure, connectHits } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import { ALGOLIA_APP_ID, ALGOLIA_API_KEY } from 'env'
import 'instantsearch.css/themes/satellite.css'
import YoutubeEmbed from '../../YoutubeEmbed'

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

const Hits = ({ hits }) => {
  const styles = useStyles()
  const hit = hits[0]

  return (
    <Box>
      {hit ? (
        <>
          <Typography variant="h4" className={styles.heading}>
            {hit.title}
          </Typography>
          <Typography variant="h6" className={styles.subheading}>
            Time Required
          </Typography>
          <Typography variant="body1">
            Setup Time: {hit.details.setupDuration} minutes <br />
            Duration: {hit.details.durationMins} minutes
          </Typography>
          <YoutubeEmbed embedId={hit.video.mediaId} />
          <Typography variant="h6" className={styles.subheading}>
            Summary
          </Typography>
          <Typography variant="body2">{hit.details.summary}</Typography>
          <Typography variant="h6" className={styles.subheading}>
            {hit.details.equipment && 'Equipment'}
          </Typography>
          <Typography variant="body2">{hit.details.equipment}</Typography>
          <Typography variant="h6" className={styles.subheading}>
            Setup
          </Typography>
          <Typography variant="body2">{hit.details.setup}</Typography>
          <Typography variant="h6" className={styles.subheading}>
            Instructions
          </Typography>
          <Typography variant="body2">{hit.details.detail}</Typography>
          <Typography variant="h6" className={styles.subheading}>
            {hit.details.assessmentTitle || ''}
          </Typography>
          <Typography variant="body2">
            {hit.details.assessmentText || ''}
          </Typography>
          <YoutubeEmbed embedId={hit.assessment_video?.mediaId} />
        </>
      ) : (
        <Grid item xs={12}>
          <EmptyPageState
            IconComp={() => <PartyPopperIcon />}
            title="No activity found."
            removeButton
          />
        </Grid>
      )}
    </Box>
  )
}

const SingleHit = connectHits(Hits)

const SingleActivitiesPage = () => {
  const classes = useStyles()
  const { id } = useParams()

  const query = 'objectID:' + id
  return (
    <>
      <Box className={classes.container}>
        <Container maxWidth="md">
          <InstantSearch
            indexName="coach_activities_dev"
            searchClient={searchClient}
          >
            <SingleHit />
            <Configure filters={`${query}`} hitsPerPage={1} />
          </InstantSearch>
        </Container>
      </Box>
    </>
  )
}

export default SingleActivitiesPage
