import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import useStyles from './styles'
import { formatMoneyFromCents } from 'lib/utils/number'

/*
 * CoachInsightsStatsCard Component
 *
 * This component displays a card with an icon, a value, and a description.
 *
 * Props:
 * - icon: A React component to render as the icon.
 * - value: The value to be displayed prominently in the card.
 * - description: A brief description or label for the value.
 * - comparisonValue: The value to be displayed as a comparison.
 */
const CoachInsightsStatsCard = ({
  icon: Icon,
  value,
  description,
  avatarBackground,
  iconColor,
  valueTestId,
  comparisonValue,
  isCurrency,
  ...props
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const renderComparison = () => {
    if (comparisonValue == null) return null

    const isPositive = comparisonValue >= 0
    const prefix = isPositive ? '+' : ''

    return (
      <div className={classes.comparisonContainer}>
        <span
          className={`${classes.comparisonValue} ${
            isPositive ? classes.positive : classes.negative
          }`}
        >
          {isPositive ? '↑' : '↓'} {prefix}
          {isCurrency ? formatMoneyFromCents(comparisonValue) : comparisonValue}
        </span>
        {!isMobile && (
          <span className={classes.comparisonLabel}>Compared to Goal</span>
        )}
      </div>
    )
  }

  return (
    <div data-testid={valueTestId} className={classes.card}>
      <div className={classes.cardContent}>
        {Icon ? (
          <div
            className={classes.avatar}
            style={{ backgroundColor: avatarBackground }}
          >
            <Icon className={classes.icon} style={{ color: iconColor }} />
          </div>
        ) : (
          <div />
        )}
        <div className={classes.valueContainer}>
          <p className={classes.value}>{value}</p>
          <p className={classes.description}>{description}</p>
          {renderComparison()}
        </div>
      </div>
    </div>
  )
}

export default CoachInsightsStatsCard
