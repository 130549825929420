import algoliasearch from 'algoliasearch/lite'
import compact from 'lodash/compact'
import flatMap from 'lodash/fp/flatMap'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import uniqBy from 'lodash/fp/uniqBy'
import { ALGOLIA_APP_ID, ALGOLIA_API_KEY } from 'env'
import { placesQuery } from 'lib/search/places-client'
import { CoachHit, FacilityHit, PlaceHit } from './search-box/hit'

export const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

const hitsToOptions = hitToOptions =>
  pipe(get('hits'), flatMap(hitToOptions), uniqBy('uniqueKey'))

const matchedOn = ({ _highlightResult }, attributes) =>
  attributes.some(
    attribute => _highlightResult[attribute].matchLevel !== 'none',
  )

const hitToFacilityAndCoachOptions = hit =>
  compact([
    matchedOn(hit, ['coach_name']) && new CoachHit(hit),
    matchedOn(hit, ['facility_name', 'zip', 'city', 'state']) &&
      new FacilityHit(hit),
  ])

const searchPlaces = async query =>
  placesQuery(query).then(hitsToOptions(hit => new PlaceHit(hit)))

const searchFacilitiesAndCoaches = (query, index) =>
  client
    .initIndex(index)
    .search(query, {
      restrictSearchableAttributes: [
        'facility_name',
        'zip',
        'city',
        'state',
        'coach_name',
      ],
      attributesToRetrieve: [
        'facility_name',
        '_geoloc',
        'city',
        'state',
        'coach_name',
      ],
    })
    .then(hitsToOptions(hitToFacilityAndCoachOptions))

export const autocompleteSearch = index => query =>
  Promise.all([searchFacilitiesAndCoaches(query, index), searchPlaces(query)])
