import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined'
import Divider from '@material-ui/core/Divider'
import RedeemIcon from '@mui/icons-material/Redeem'
import { useStyles } from './styles'
import EmailFieldWithSuggestions from 'components/form-util/email-field-with-suggestions'
import CtrlCheckbox from 'components/form-util/ctrl-checkbox'

const GIFT_LESSON_PACK = gql`
  mutation GiftLessonPack($input: TransferLessonCreditInput!) {
    transferLessonCredit(input: $input) {
      success
      message
    }
  }
`

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required'),
  confirmAccuracy: yup
    .bool()
    .oneOf([true], 'Please confirm the accuracy of the information'),
})

const GiftLessonPackModal = ({ open, onClose, lessonPack }) => {
  const classes = useStyles()
  const history = useHistory()
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      confirmAccuracy: false,
    },
  })

  const [giftLessonPack, { loading: mutationLoading }] = useMutation(
    GIFT_LESSON_PACK,
    {
      onCompleted: data => {
        setSuccess(data.transferLessonCredit.success)
        setError(
          data.transferLessonCredit.success
            ? ''
            : data.transferLessonCredit.message,
        )
      },
    },
  )

  const onSubmit = async values => {
    setError('')
    return giftLessonPack({
      variables: {
        input: {
          lessonPackId: lessonPack.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          lessonCreditCount: parseInt(lessonPack.totalCredits),
        },
      },
    })
  }

  const handleClose = () => {
    reset()
    setError('')
    setSuccess(false)
    onClose()
  }

  const handlePurchaseMore = () => {
    const { coach, id: lessonTypeId, locations } = lessonPack.lessonType
    const locationId = locations[0].id
    const relativeBookingUrl = `/coach${
      coach.bookingUrl.split('/coach')[1]
    }?lessonTypeId=${lessonTypeId}&locationId=${locationId}&isLessonPacks=true`
    history.push(relativeBookingUrl)
    handleClose()
  }

  const handleHome = () => {
    history.push('/club')
    handleClose()
  }

  if (success) {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.successContent}>
            <Box className={classes.celebrationIcon}>
              <CelebrationOutlinedIcon />
            </Box>
            <Typography variant="h6" gutterBottom>
              Success!
            </Typography>
            <Typography variant="body1" paragraph>
              The recipient has been notified to accept the lesson pack. You
              will receive a confirmation once the credits are successfully
              transferred to their account. Please note, the acceptance link
              will expire in 7 days. If it is not accepted by then, you'll
              receive a notice when the pack is added back to your account.
            </Typography>
            <Box className={classes.successActions}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePurchaseMore}
                className={classes.successButton}
              >
                Purchase Another Pack
              </Button>
              <Button
                color="primary"
                onClick={handleHome}
                className={classes.homeButton}
              >
                Home
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        Gift Lesson Pack
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.form}>
          <Typography variant="body1" className={classes.description}>
            If you wish to cancel the pack before acceptance, please reach out
            to support at support@pga.org. Once accepted, it's final.{' '}
            <strong>The recipient will be notified.</strong>
          </Typography>

          {error && (
            <Alert severity="error" className={classes.alert}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  className={classes.field}
                  name="firstName"
                  inputRef={register}
                  label="Recipient First Name"
                  type="text"
                  fullWidth
                  required
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.field}
                  name="lastName"
                  inputRef={register}
                  label="Recipient Last Name"
                  type="text"
                  fullWidth
                  required
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              </Grid>
            </Grid>

            <EmailFieldWithSuggestions
              label="Recipient Email"
              control={control}
              name="email"
              errors={errors}
              classes={classes}
              fullWidth
              required
            />

            <CtrlCheckbox
              name="confirmAccuracy"
              label={
                <Typography variant="body2">
                  Confirm the accuracy of the above information by checking this
                  box.
                </Typography>
              }
              control={control}
              errors={errors}
            />

            <Box className={classes.buttonContainer}>
              <Button onClick={handleClose} variant="text">
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={mutationLoading}
              >
                {mutationLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <RedeemIcon className={classes.redeemIcon} />
                    Gift Lessons
                  </>
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default GiftLessonPackModal
