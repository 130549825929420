import PGALogo from 'images/pga-logo-navy.png'
import Club1916Logo from 'images/club_1916_logo.png'

import { APP_ENVIRONMENT } from 'env'

const RulesAndRegulations = [
  'Simulator bay users must reserve simulator time in advance and use the simulator only during their reserved time.',
  'All individuals entering the facility premises must present a valid form of identification upon arrival.',
  'Appointment cancellations must be made at least 24 hours in advance. Any cancellation made within the 24-hour window prior to the appointment time will result in the individual being held responsible for the appointment fees immediately upon registration.',
  'A maximum of four players are allowed in one simulator bay at one time.',
  'All children under the age of 18 must be accompanied by an adult inside the Coaching Center at all times.',
  'The simulator bay doors must remain closed at all times.',
  'Food and alcoholic beverages may not be brought into the Coaching Center.',
  'All balls should be hit from the middle of the hitting mat. All shots must be directed towards the hitting screen.',
  'Only one player shall be on the hitting mat at any given time. Everyone must pay attention and maintain a safe distance from the player on the hitting mat.',
  'Golf shots may only be done using conventional swings. No "Happy Gilmore" swings.',
  'To register a shot on the screen you must have a green light from the ball placement.',
  'Simulator bay users must follow all instructions and directives from Coaching Center staff. Please consult a Coaching Center staff member with questions. Report all accidents to Coaching Center staff immediately.',
  'All guests must sign the Assumption of Risk, Waiver and Release of Liability, and Indemnity Agreement before using the simulator. Simulator bay users are financially responsible for any personal injury or property damage caused by the failure to follow these Rules and Regulations.',
]

const PracticeSessionTypeVariants = {
  default:
    APP_ENVIRONMENT === 'production'
      ? '91c3e426-b287-4543-b483-77f511e97631'
      : 'c928e697-6360-48bd-8e03-00e0d6f28d30', // Club 1916 Members Only
  ntpga:
    APP_ENVIRONMENT === 'production'
      ? '7d8c40ab-afe7-4b86-84a6-e3d621150b25'
      : 'a5b07a52-dec0-4e81-b3f7-e03f2c2d8d64', // NTPGA
}

const PracticeSessionTypeVariantContent = [
  {
    // Club 1916 Members
    id: PracticeSessionTypeVariants.default,
    bookingNotice: {
      image: Club1916Logo,
      content:
        'Bay reservations are only available to Club 1916 members and can be booked up to 2 weeks in advance.',
    },
    bookingLimit: 14, // days
    membershipVerification: {
      content: "Please enter the attendee's Club 1916 member number.",
      fieldLength: 5,
    },
    rulesAndRegulations: RulesAndRegulations,
    cancellationPolicy:
      'Cancellation Policy: The cancellation policy requires a notice of at least 24 hours before the scheduled appointment. If a reservation is made within the 24-hour timeframe, the responsibility for any cancellation fees is incurred at the time of booking.',
  },
  {
    // NTPGA Memebers
    id: PracticeSessionTypeVariants.ntpga,
    bookingNotice: {
      image: PGALogo,
      content:
        'Bay reservations are available to Northern Texas PGA Members and Associates and can be booked up to 1 week in advance.',
    },
    bookingLimit: 7, // days
    membershipVerification: {
      content: 'Please enter your PGA of America Membership Number (PGA ID)',
      fieldLength: 8,
    },
    rulesAndRegulations: [
      'Simulator bay users must reserve simulator time in advance and use the simulator only during their reserved time.',
      'All individuals entering the facility premises must present a valid form of identification upon arrival.',
      'A maximum of four players are allowed in one simulator bay at one time.',
      'All children under the age of 18 must be accompanied by an adult inside the Coaching Center at all times.',
      'The simulator bay doors must remain closed at all times.',
      'Food and alcoholic beverages may not be brought into the Coaching Center.',
      'All balls should be hit from the middle of the hitting mat. All shots must be directed towards the hitting screen.',
      'Only one player shall be on the hitting mat at any given time. Everyone must pay attention and maintain a safe distance from the player on the hitting mat.',
      'Golf shots may only be done using conventional swings. No "Happy Gilmore" swings.',
      'To register a shot on the screen you must have a green light from the ball placement.',
      'Simulator bay users must follow all instructions and directives from Coaching Center staff. Please consult a Coaching Center staff member with questions. Report all accidents to Coaching Center staff immediately.',
      'All guests must sign the Assumption of Risk, Waiver and Release of Liability, and Indemnity Agreement before using the simulator. Simulator bay users are financially responsible for any personal injury or property damage caused by the failure to follow these Rules and Regulations.',
    ],
    cancellationPolicy: '',
  },
]

export {
  PracticeSessionTypeVariants,
  PracticeSessionTypeVariantContent,
  RulesAndRegulations,
}
