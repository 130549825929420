import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export const generatePDF = async (element, reportName) => {
  try {
    if (!element) {
      console.error('Element not found')
      return null
    }

    // Wait for all charts to fully render
    await new Promise(resolve => setTimeout(resolve, 2000))

    // Pre-process charts to ensure they're rendered correctly
    const charts = element.querySelectorAll('.recharts-wrapper')
    charts.forEach(chart => {
      chart.style.width = '100%'
      chart.style.height = '300px'
      const svg = chart.querySelector('svg')
      if (svg) {
        svg.style.width = '100%'
        svg.style.height = '100%'
      }
    })

    // Calculate optimal scale based on content width
    const scale = Math.min(2, 2000 / element.offsetWidth)

    // Capture the DOM element as canvas with improved settings
    const canvas = await html2canvas(element, {
      scale: scale,
      useCORS: true,
      logging: false,
      allowTaint: true,
      backgroundColor: '#ffffff',
      windowWidth: element.scrollWidth,
      windowHeight: element.scrollHeight,
      onclone: clonedDoc => {
        const clonedElement = clonedDoc.body.querySelector('[role="tabpanel"]')
        if (clonedElement) {
          // Ensure proper sizing in cloned document
          clonedElement.style.width = `${element.offsetWidth}px`
          clonedElement.style.height = 'auto'
          clonedElement.style.overflow = 'visible'

          // Handle charts in cloned document
          const clonedCharts = clonedElement.querySelectorAll(
            '.recharts-wrapper',
          )
          clonedCharts.forEach(chart => {
            chart.style.width = '100%'
            chart.style.height = '300px'
            chart.style.marginBottom = '20px'
            const svg = chart.querySelector('svg')
            if (svg) {
              svg.style.width = '100%'
              svg.style.height = '100%'
            }
          })
        }
      },
    })

    // Initialize PDF document
    // eslint-disable-next-line new-cap
    const PDF = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
    })

    // Get page dimensions
    const pageWidth = PDF.internal.pageSize.getWidth()
    const pageHeight = PDF.internal.pageSize.getHeight()

    // Calculate margins
    const margin = 40
    const usableWidth = pageWidth - 2 * margin

    // Calculate image dimensions maintaining aspect ratio
    const aspectRatio = canvas.width / canvas.height
    const imgWidth = usableWidth
    const imgHeight = imgWidth / aspectRatio

    // Add title
    PDF.setFontSize(16)
    PDF.text(reportName, margin, margin)

    // Calculate content height per page
    const contentHeight = pageHeight - 2 * margin
    const totalPages = Math.ceil(imgHeight / contentHeight)

    // Add content page by page
    for (let i = 0; i < totalPages; i++) {
      if (i > 0) {
        PDF.addPage()
      }

      const sourceY = i * contentHeight * (canvas.height / imgHeight)
      const sourceHeight = Math.min(
        canvas.height - sourceY,
        contentHeight * (canvas.height / imgHeight),
      )

      PDF.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        margin,
        i === 0 ? margin + 20 : margin, // Extra space for title on first page
        imgWidth,
        imgHeight,
        '',
        'FAST',
        0,
        sourceY,
        canvas.width,
        sourceHeight,
      )
    }

    // Generate optimized PDF blob
    const blob = PDF.output('blob', {
      compress: true,
      quality: 0.95,
    })

    return blob
  } catch (error) {
    console.error('Error generating PDF:', error)
    throw error
  }
}
