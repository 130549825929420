import React, { useLayoutEffect } from 'react'
import NotFound from 'components/not-found.js'
import { LinearProgress } from '@material-ui/core'
import { useAuth, withAuthenticatedRoute, redirectToLogin } from 'lib/auth'
import { useQuery, useMutation } from '@apollo/client'
import { useGetPracticeSessionTypes, GET_STUDENTS } from './queries'
import { CREATE_STUDENT } from 'components/add-student-modal/mutation'
import { FRISCO_COACHING_CENTER_SLUG } from 'utils/constants'
import {
  PracticeSessionTypeVariants,
  PracticeSessionTypeVariantContent,
} from './constants'
import { DateTime } from 'luxon'
import qs from 'query-string'
import SelectTimeSlotLayout from './select-time-slot-layout'
import { useLocation, useParams } from 'react-router-dom'
import ConfirmMemberNumber, {
  findStudentByExternalID,
} from './confirm-member-number'
import ReviewReservation from './review-reservation'
import scrollToTopOnMount from 'lib/utils/scroll-to-top-on-mount'

export const getPracticeSessionContent = practiceSessionTypeID => {
  return PracticeSessionTypeVariantContent.find(
    content => content.id === practiceSessionTypeID,
  )
}

const Reservations = () => {
  const { isLoggedIn, isCoach, user } = useAuth()

  // Force the Auth Context to be Consumer.
  // This is necessary as the User Type impacts queries and mutations
  // eg. currentUser.students Query, createStudent Mutation
  if (isLoggedIn && isCoach) redirectToLogin('consumer')

  const location = useLocation()
  const params = useParams()

  const { data: studentsData } = useQuery(GET_STUDENTS, {
    skip: !isLoggedIn,
  })

  const [createStudent] = useMutation(CREATE_STUDENT)

  const {
    startDateTime,
    studentId,
    memberNumber,
    practiceSessionTypeId,
  } = qs.parse(location.search)

  const { code } = params
  const practiceSessionID =
    practiceSessionTypeId ?? PracticeSessionTypeVariants[code || 'default']
  const { bookingLimit } = getPracticeSessionContent(practiceSessionID)

  scrollToTopOnMount({ query: `${startDateTime}${memberNumber}` })

  const {
    loading,
    error,
    data: practiceSessionData,
    refetch,
  } = useGetPracticeSessionTypes({
    slug: FRISCO_COACHING_CENTER_SLUG,
    startDateTime: DateTime.local()
      .startOf('hour')
      .plus({ hour: 1 })
      .toISO(),
    endDateTime: DateTime.local()
      .endOf('day')
      .plus({ days: bookingLimit })
      .toISO(),
  })

  useLayoutEffect(() => {
    const createStudentIfNeeded = async () => {
      if (
        isLoggedIn &&
        (studentsData?.currentUser?.students?.length === 0 ||
          !findStudentByExternalID(
            studentsData?.currentUser?.students,
            user.externalId,
          ))
      ) {
        try {
          await createStudent({
            variables: {
              params: {
                isMinor: false,
                studentFirstName: user?.firstName,
                studentLastName: user?.lastName,
                email: user?.email,
              },
            },
          })
        } catch (err) {
          console.error('Error creating student:', err)
        }
      }
    }

    createStudentIfNeeded()
  }, [isLoggedIn, studentsData, createStudent, user])

  useLayoutEffect(() => {
    if (startDateTime == null) {
      refetch()
    }
  }, [startDateTime, refetch])

  if (loading) return <LinearProgress />

  if (error) {
    return <NotFound returnUrl={`${FRISCO_COACHING_CENTER_SLUG}`} />
  }

  const { academy } = practiceSessionData
  const selectedPracticeSessionType =
    academy?.practiceSessionTypes.find(type => type.id === practiceSessionID) ??
    academy?.practiceSessionTypes[0]

  if (studentId && memberNumber && practiceSessionTypeId) {
    return (
      <ReviewReservation
        academy={academy}
        studentId={studentId}
        startDateTime={startDateTime}
        practiceSession={selectedPracticeSessionType}
      />
    )
  }

  if (startDateTime) {
    return (
      <ConfirmMemberNumber
        academy={academy}
        practiceSession={selectedPracticeSessionType}
        isLoggedIn={isLoggedIn}
        externalId={user?.externalId}
      />
    )
  }

  return (
    <SelectTimeSlotLayout
      academy={academy}
      practiceSession={selectedPracticeSessionType}
      refetch={refetch}
    />
  )
}

export default withAuthenticatedRoute(Reservations)
