import React, { useState, useEffect } from 'react'
import { Paper } from '@mui/material'
import { Stepper, Step, StepLabel, Hidden } from '@material-ui/core'
import useStyles from './styles'
import GeneralInformation from './general-information'
import FacilityMetrics from './facility-metrics'
import MonthlyInputs from './monthly-inputs'
import Confirmation from './confirmation'
import PageHeader from 'pages/pga-coach/students/student-show-page/header-components/page-header'
import CoachingGoals from './coaching-goals'
import { useParams, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GetReportsQuery } from '../reports/query'

const STEP_GENERAL_INFORMATION = 'General Information'
const STEP_FACILITY_METRICS = 'Facility Metrics'
const STEP_MONTHLY_INPUTS = 'Monthly Inputs'
const STEP_CONFIRMATION = 'Confirmation'
const STEP_COACHING_GOALS = 'Coaching Goals'

const steps = [
  STEP_GENERAL_INFORMATION,
  STEP_COACHING_GOALS,
  STEP_FACILITY_METRICS,
  STEP_MONTHLY_INPUTS,
  STEP_CONFIRMATION,
]

const CreateReport = () => {
  const classes = useStyles()
  const { reportId } = useParams()
  const location = useLocation()
  const [activeStep, setActiveStep] = useState(reportId ? 1 : 0)
  const [report, setReport] = useState(null)

  // Detect if we are in edit mode based on the URL
  const isEditMode = !location.pathname.includes('create-report')

  // Query to get reports
  const { data: reportsData, loading } = useQuery(GetReportsQuery)

  // Effect to find and set the current report if we are in edit mode
  useEffect(() => {
    if (reportId && reportsData?.reports) {
      const currentReport = reportsData.reports.find(r => r.id === reportId)
      if (currentReport) {
        setReport(currentReport)
      }
    }
  }, [reportId, reportsData])

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <GeneralInformation
            onNext={handleNext}
            setReport={setReport}
            initialReport={report}
            loading={loading}
            isEditMode={isEditMode}
          />
        )
      case 1:
        return (
          <CoachingGoals
            onNext={handleNext}
            onBack={handleBack}
            report={report}
            loading={loading}
            isEditMode={isEditMode}
          />
        )
      case 2:
        return (
          <FacilityMetrics
            onNext={handleNext}
            onBack={handleBack}
            report={report}
            loading={loading}
            isEditMode={isEditMode}
          />
        )
      case 3:
        return (
          <MonthlyInputs
            onNext={handleNext}
            onBack={handleBack}
            report={report}
            loading={loading}
            isEditMode={isEditMode}
          />
        )
      case 4:
        return <Confirmation report={report} />
      default:
        return null
    }
  }

  return (
    <div>
      <Hidden mdUp>
        <PageHeader
          title={reportId ? 'Edit Report' : 'Create Report'}
          crumbs={[{ label: 'Reports', to: '/pga-coach/reports' }]}
        />
      </Hidden>
      <div className={classes.createReportContainer}>
        <Hidden smDown>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconProps={{ icon: '' }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Hidden>

        <Paper
          elevation={1}
          sx={{
            marginTop: '6rem',
            p: 6,
            maxWidth: 600,
            mx: 'auto',
            borderRadius: '12px',
            border: '1px solid #E0E0E0',
            gap: '10px',
            '@media (max-width: 960px)': {
              marginTop: '0',
              p: 3,
              borderRadius: '0',
              border: 'none',
              boxShadow: 'none',
            },
          }}
        >
          {renderStepContent()}
        </Paper>
      </div>
    </div>
  )
}

export default CreateReport
