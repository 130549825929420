import React, { useState, useEffect } from 'react'
import { Typography, TextField, FormControl, Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import FacilitiesAlgolia from 'components/coaches-profile/profile-components/FacilitiesAlgolia'
import { GetFacilityQuery, CreateReportMutation } from '../query'
import { useQuery, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'

const GeneralInformation = ({ onNext, setReport, initialReport, loading }) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [createReport] = useMutation(CreateReportMutation)
  const [isLoading, setIsLoading] = useState(false)
  const {
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      reportName: initialReport?.name || '',
      year: initialReport?.year || '',
      facility: initialReport?.facilityId || '',
    },
    mode: 'onChange',
    resolver: (values) => {
      const errors = {}

      if (!values.reportName) {
        errors.reportName = {
          type: 'required',
          message: 'Report name is required',
        }
      }

      if (!values.year) {
        errors.year = {
          type: 'required',
          message: 'Year is required',
        }
      }

      if (!values.facility) {
        errors.facility = {
          type: 'required',
          message: 'Facility is required',
        }
      }

      return {
        values,
        errors: Object.keys(errors).length > 0 ? errors : {},
      }
    },
  })

  const [facilityName, setFacilityName] = useState('')
  const [facilityId, setFacilityId] = useState('')

  const { data, refetch } = useQuery(GetFacilityQuery, {
    variables: { facilityId },
  })

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      if (!facilityId) {
        enqueueSnackbar('Please select a facility', { variant: 'error' })
        return
      }

      const { data: reportData } = await createReport({
        variables: {
          input: {
            name: data.reportName,
            year: parseInt(data.year),
            facilityId: data.facility,
          },
        },
      })

      if (reportData?.createReport?.report) {
        setReport(reportData.createReport.report)
        onNext()
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (initialReport?.facilityId) {
      setFacilityId(initialReport.facilityId)
      refetch({ facilityId: initialReport.facilityId })
    }
  }, [initialReport, refetch])

  useEffect(() => {
    if (data?.facility) {
      setValue('facility', data.facility.id, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }, [data, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Typography variant="h5" gutterBottom>
        Yearly Coaching Metrics
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mb: 3, fontSize: '16px', lineHeight: '24px', color: '#0000008A' }}
      >
        Enter the following information about the report you want to generate.
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextField
          {...register('reportName')}
          required
          onChange={(e) => {
            setValue('reportName', e.target.value, {
              shouldValidate: true,
              shouldDirty: true,
            })
          }}
          label="Report Name"
          error={!!errors?.reportName}
          helperText={errors?.reportName?.message}
          fullWidth
        />

        <FormControl required fullWidth error={!!errors?.year}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Year"
              views={['year']}
              maxDate={dayjs()}
              value={watch('year') ? dayjs().year(parseInt(watch('year'))) : null}
              onChange={(newValue) => {
                const yearValue = newValue ? newValue.year().toString() : ''
                setValue('year', yearValue, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }}
              slotProps={{
                textField: {
                  ...register('year', { required: 'Year is required' }),
                  required: true,
                  error: !!errors?.year,
                  helperText: errors?.year?.message,
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl required fullWidth error={!!errors?.facility}>
          <FacilitiesAlgolia
            {...register('facility')}
            required
            name="facility"
            value={facilityName}
            onChange={(e) => {
              setFacilityName(e.target.value)
            }}
            onSelect={(facility) => {
              setFacilityId(facility.facility_id)
              setFacilityName(facility.facility_name)
              setValue('facility', facility.facility_id, {
                shouldValidate: true,
                shouldDirty: true,
              })
            }}
            error={!!errors?.facility}
            helperText={errors?.facility?.message}
            InputProps={{
              startAdornment: <SearchIcon sx={{ mr: 1 }} />,
            }}
          />
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.push('/pga-coach/reports')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress size={16} />}
        >
          Save and Continue
        </Button>
      </Box>
    </form>
  )
}

export default GeneralInformation
