const SAMPLE_REPORTS = {
  'alison-curdt-sample': {
    effectiveHourlyRate: 11911, // $150
    percentageTimeCoaching: 79.41, // 27 hours coaching, 7 hours administrative per week
    numberOfStudents: 1296, // unique students per week
    ytdRevenueInCents: 19440000, // Based on $150 * 108 hours * 12 months
    ytdProfitInCents: 18720000, // Revenue - (rent + expenses) * 12
    monthlyFinancials: Array(12)
      .fill()
      .map((_, index) => ({
        month: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ][index],
        revenueInCents: 1620000, // $150 * 27 hours * 4 weeks
        rentPaidToClubInCents: 50000, // $500
        purchasesFromClubInCents: 0,
        otherExpensesInCents: 7500, // $75 other expenses
        marketingSpendInCents: 2500, // $25 marketing
      })),
    ytdNewStudents: 192, // 4 new students per week * year
    currentContacts: 48, // Based on 4 leads per month
    contactConversion: 25, // 1 out of 4 leads converts
    contactValueInCents: 660000, // $6600 ($600 monthly + $5000 initiation)
    totalNewContactsNeeded: 48, // To maintain current growth
    numberOfLeads: 288,
  },
}

export const useMockQuery = (sampleReportId = null) => {
  if (!sampleReportId || !SAMPLE_REPORTS[sampleReportId]) {
    return { data: null, facility: null }
  }

  return {
    data: SAMPLE_REPORTS[sampleReportId],
    facility: {
      directPaymentToFacilityInCents: Array(12)
        .fill()
        .map((_, index) => ({
          month: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ][index],
          value: 50000, // $500 monthly rent
        })),
      newStudents: 192, // 4 new students per week * year
      totalStudents: 1296, // unique students per week
      additionalRevenueToFacility: Array(12)
        .fill()
        .map((_, index) => ({
          month: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ][index],
          agreedModel: 702467,
          bvgiModel: 2835000,
          pgaSuggestedModel: 8100000,
        })),
      totalRevenueToClub: 2640000, // $26,400 (4 new members * $6600)
      participantsTurnedMembers: 4, // 4 new members per year
      hoursWithProfessional: 1404, // 27 hours * 52 weeks
    },
  }
}
