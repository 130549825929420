import { useState, useEffect } from 'react'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import CoachInsightsStatsCard from 'components/coach-insights-stats-card'
import useStyles from './styles'
import CoachInsightsYTDChart from 'components/coach-insights-ytd-chart'
import CoachInsightsProfitChart from 'components/coach-insights-profit'
import { formatMoneyFromCents } from 'lib/utils/number'
import EmptyPage from 'components/empty-page-state'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import { Skeleton } from '@material-ui/lab'
import {
  transformMonthlyFinancials,
  transformYtdRevenue,
  transformYtdExpenses,
  calculateTotalExpenses,
} from './utils'
import { formatFromIntToCurrency, formatNumberWithCommas } from 'utils/number'
import { useQuery } from '@apollo/client'
import { GetBusinessGoalsByReportIdQuery } from '../../create-report/query'

const MyBusinessInsights = ({
  businessInsights,
  businessInsightsLoading,
  reportId,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const classes = useStyles()
  const [monthlyFinancials, setMonthlyFinancials] = useState([])
  const [ytdRevenue, setYtdRevenue] = useState([])
  const [ytdExpenses, setYtdExpenses] = useState([])
  const [ytdExpensesInCents, setYtdExpensesInCents] = useState(0)
  const [goalHourlyRate, setGoalHourlyRate] = useState(null)
  const [goalLeadsPerMonth, setGoalLeadsPerMonth] = useState(null)

  const renderSkeletonCard = () => (
    <div className={classes.skeletonCard}>
      <Skeleton variant="circle" width={40} height={40} />
      <Skeleton variant="text" width="60%" height={40} />
      <Skeleton variant="text" width="40%" />
    </div>
  )

  useEffect(() => {
    if (businessInsights?.monthlyFinancials) {
      setMonthlyFinancials(
        transformMonthlyFinancials(businessInsights.monthlyFinancials),
      )
      setYtdRevenue(transformYtdRevenue(businessInsights.monthlyFinancials))
      setYtdExpenses(transformYtdExpenses(businessInsights.monthlyFinancials))
      setYtdExpensesInCents(
        calculateTotalExpenses(businessInsights.monthlyFinancials),
      )
    }
  }, [businessInsights])

  const { data: businessGoalsData } = useQuery(
    GetBusinessGoalsByReportIdQuery,
    {
      variables: { reportId: reportId },
      skip: !reportId,
    },
  )

  useEffect(() => {
    if (businessGoalsData?.businessGoals && businessInsights) {
      const goals = businessGoalsData.businessGoals
      const goalHourlyRateCalc =
        goals.revenuePerMonthInCents / goals.hoursPerMonth
      const businessInsightsHourlyRate = Math.round(
        businessInsights.effectiveHourlyRate,
      )
      setGoalHourlyRate(businessInsightsHourlyRate - goalHourlyRateCalc)
      setGoalLeadsPerMonth(businessInsights.numberOfLeads - goals.leadsPerMonth)
    }
  }, [businessGoalsData, businessInsights])

  return (
    <div className={classes.gridContainer}>
      {businessInsightsLoading && (
        <Grid container spacing={isMobile ? 2 : 3}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={6} sm={4} key={index}>
              {renderSkeletonCard()}
            </Grid>
          ))}
          <Grid item sm={12} xs={12}>
            <Skeleton variant="rect" height={300} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton variant="rect" height={300} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton variant="rect" height={300} />
          </Grid>
        </Grid>
      )}
      {!businessInsightsLoading && Object.keys(businessInsights)?.length > 0 ? (
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={6} sm={3} key="effectiveHourlyRate">
            <CoachInsightsStatsCard
              icon={MonetizationOnOutlinedIcon}
              value={formatMoneyFromCents(
                businessInsights?.effectiveHourlyRate || 0,
              )}
              description={
                isMobile ? 'Avg. Hourly Rate' : 'Effective Hourly Rate'
              }
              comparisonValue={goalHourlyRate || null}
              isCurrency={true}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="percentageTimeCoaching">
            <CoachInsightsStatsCard
              icon={AccessTimeOutlinedIcon}
              value={`${businessInsights?.percentageTimeCoaching || 'n/a'}%`}
              description={
                isMobile ? 'Time Coaching' : 'Percentage Time Coaching'
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} key="numberOfStudents">
            <CoachInsightsStatsCard
              icon={GroupOutlinedIcon}
              value={formatNumberWithCommas(
                businessInsights?.numberOfStudents || 0,
              )}
              description={isMobile ? 'Students' : 'Total Students'}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="ytdNewStudents">
            <CoachInsightsStatsCard
              icon={PersonAddAltOutlinedIcon}
              value={formatNumberWithCommas(
                businessInsights?.ytdNewStudents || 0,
              )}
              description="New Students"
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <CoachInsightsYTDChart
              barChartData={ytdRevenue}
              title="YTD Revenue"
              value={`$${formatFromIntToCurrency(
                (businessInsights?.ytdRevenueInCents / 100).toFixed(2),
              )}`}
              stacked={false}
              colors={['#043362', '#cf0f01']}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="numberOfLeads ">
            <CoachInsightsStatsCard
              icon={PersonOutlinedIcon}
              value={formatNumberWithCommas(
                businessInsights?.numberOfLeads || 0,
              )}
              description={isMobile ? 'Leads' : 'Total Leads'}
              comparisonValue={goalLeadsPerMonth}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="contactConversion">
            <CoachInsightsStatsCard
              icon={PersonAddAltOutlinedIcon}
              value={`${businessInsights?.contactConversion || 'n/a'}%`}
              description={'Conversion Success Rate'}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="contactValueInCents">
            <CoachInsightsStatsCard
              icon={StarBorderOutlinedIcon}
              value={formatMoneyFromCents(
                businessInsights?.contactValueInCents || 0,
              )}
              description={'Avg Revenue Per Contact'}
            />
          </Grid>
          <Grid item xs={6} sm={3} key="totalNewContactsNeeded">
            <CoachInsightsStatsCard
              icon={StarBorderOutlinedIcon}
              value={businessInsights?.totalNewContactsNeeded || 'n/a'}
              description="Target Contacts to Reach Goal"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CoachInsightsProfitChart
              lineChartData={monthlyFinancials}
              title="2024 Net Revenue"
              value={formatMoneyFromCents(
                businessInsights?.ytdProfitInCents || 0,
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CoachInsightsYTDChart
              barChartData={ytdExpenses}
              title="YTD Expenses"
              value={`$${formatFromIntToCurrency(
                (ytdExpensesInCents / 100).toFixed(2),
              )}`}
            />
          </Grid>
        </Grid>
      ) : (
        <div
          data-testid="business-insights-empty-state"
          className={classes.gridContainer}
        >
          {!businessInsightsLoading && (
            <EmptyPage
              actionFn={() => {}}
              IconComp={ShowChartIcon}
              title="My Business Insights"
              caption="There is currently no business insights data available for this time period."
              buttonText="Create Report"
              actionFnSecondary={() => {}}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MyBusinessInsights
