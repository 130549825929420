import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  gridRoot: {
    marginTop: '50px',
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  loadAction: {
    color: theme.palette.secondary.main,
    backgroundColor: '#fff',
    marginTop: '10px',
    marginBottom: '20px',
    border: `1px solid ${theme.palette.secondary.main}`,
    fontSize: '16px',
    padding: '10px 36px',
  },
  arrowDownwardIcon: {
    fontSize: '24px !important',
  },
  askWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
}))

export default useStyles
