import React from 'react'
import { Grid, FormControl } from '@material-ui/core'

export default function Filter({ children }) {
  return (
    <Grid item md={2} sm={12} xs={12}>
      <FormControl fullWidth>{children}</FormControl>
    </Grid>
  )
}
